import React from 'react';
import styled from 'styled-components';
import {
  Box,
  theme,
} from '@components';


const StyledBox = styled(Box)`
  border: 1px solid ${theme.colors.border};
  border-radius: ${theme.radius.normal};
`;

const Panel = (props) => (
  <StyledBox
    p={[2, 3]}
    mb={[2, 3]}
    {...props}
  />
);

export default Panel;
