import isBrowser from './isBrowser';

const getCookie = (name) => {
  if (!isBrowser()) return null;
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');
};

export default getCookie;
