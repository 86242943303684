import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from '../Grid';
import theme from '../styled/theme';

const ModalOverlay = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.3);
`;

const StyledModal = styled(Box)`
  position: relative;
  max-height: 95%;
  overflow: hidden;
  z-index: 1010;
  background: white;
  box-shadow: ${theme.shadow};
  box-sizing: border-box;
  border-radius: ${theme.radius.normal};
`;

const ModalInner = styled(Box)`
  width: 100%;
  height: 100%;
  max-height: 70vh;
  overflow: auto;
  box-sizing: border-box;
  border-top: 1px solid ${theme.colors.border};
  font-size: 0.9em;
`;

const CloseButton = styled.button`
  background: none;
  outline: none;
  border: none;
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  cursor: pointer;
  padding: 0.75em;
  line-height: 0.5;
  font-size: 2em;
  color: ${theme.colors.text};
  opacity: 0.5;
  transition: ${theme.transition.set('opacity')};

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

const Title = styled.h3`
  text-align: center;
  margin: 0.75em;
`;

const Modal = ({
  closePortal, children, title, closeable,
}) => (
  <div id="portal">
    <ModalOverlay onMouseDown={closeable ? closePortal : undefined}>
      <StyledModal
        mx="auto"
        mt={['1%', '2%']}
        maxWidth={['95%', '90%', '45em']}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {title && <Title center>{title}</Title>}
        {closeable && <CloseButton onClick={closePortal}>&times;</CloseButton>}
        <ModalInner p={[2, 3, 4]}>
          {children}
        </ModalInner>
      </StyledModal>
    </ModalOverlay>
  </div>
);

Modal.propTypes = {
  closePortal: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  closeable: PropTypes.bool,
};

export default Modal;
