import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '@components';

const Wrapper = styled.div`
  position: relative;
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background: white;
  box-shadow: ${theme.shadow};
  border-radius: ${theme.radius.small};

  &:not(:empty) {
    border: 1px solid ${theme.colors.border};
  }
`;

const Dropdown = ({ children, isOpen }) => (
  <Wrapper>
    {isOpen && (
      <Inner>
        {children}
      </Inner>
    )}
  </Wrapper>
);

Dropdown.defaultProps = {
  isOpen: true,
};

Dropdown.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};

export default Dropdown;
