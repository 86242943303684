import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@components';
import { passwordLengthValidator } from '@validators';
import MESSAGES from '@messages';
import { auth } from '@helpers';
import { NEW_API_URL } from '@constants';
import Form from './Form';

const errorsMap = {
  'wrong.email_format': MESSAGES.WRONG_EMAIL_FORMAT,
  passwords_not_match: MESSAGES.PASSWORDS_NOT_MATCH,
  invalid_token: MESSAGES.PASSWORD_REMINDER_INVALID_LINK,
  'password.required': MESSAGES.PASSWORD_REQUIRED,
  'password.too_short': MESSAGES.PASSWORD_TOO_SHORT_ERROR,
};

const RegisterForm = ({ token, email }) => (
  <Form
    url={`${NEW_API_URL}/change-password/${token}`}
    title={MESSAGES.RESET_PASSWORD_TITLE}
    replaceContent={auth.getToken() ? () => <p>{MESSAGES.YOU_ARE_ALREADY_LOGGED_IN}</p> : null}
    getValues={(values) => ({ ...values, email })}
    onSuccess={({ data, setMessage }) => {
      if (data.ok) {
        setMessage({
          success: (
            <>
              <p>{MESSAGES.PASSWORD_CHANGED}</p>
              <p>
                {MESSAGES.GO_TO}
                {' '}
                <a href="/harmonogram">{MESSAGES.TO_TIMETABLE}</a>
                ,
                {' '}
                {MESSAGES.TO_LOG_IN}
              </p>
            </>
          ),
        });
      }
    }}
    errorsMap={errorsMap}
  >
    {({ errors }) => (
      <>
        <TextInput
          errors={errors}
          name="password"
          placeholder={MESSAGES.NEW_PASSWORD}
          required
          type="password"
          validators={[passwordLengthValidator()]}
        />
        <TextInput
          errors={errors}
          name="passwordConfirmation"
          placeholder={MESSAGES.REPEAT_PASSWORD}
          required
          type="password"
          validators={[
            {
              errorMessage: MESSAGES.PASSWORDS_NOT_MATCH,
              validate: (value, formValues) => value === formValues.password,
            },
          ]}
        />
      </>
    )}
  </Form>
);

RegisterForm.propTypes = {
  token: PropTypes.string,
};

export default RegisterForm;
