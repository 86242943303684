import React from 'react';
import PropTypes from 'prop-types';
import {
  GridFlex,
  Box,
  Title,
  Button,
} from '@components';

import {
  HomePageContainer,
  HomePageImage,
} from './Components';

const HomePageWhyUs = ({
  title,
  // movie,
  button,
  attributes,
  image,
  imageLarge,
}) => (
  <HomePageContainer py={[1, 2, 3]}>
    <HomePageImage
      image={image}
      width={1}
      display={['block', 'block', 'none']}
    />
    <HomePageImage
      image={imageLarge}
      width={[1, 1, '80%', '50%']}
      position="absolute"
      display={['none', 'none', 'block']}
    />
    <Box width={[1, 1, 3 / 10]} />
    <Box
      p={[3, 4, 0]}
      width={[1, 1, 7 / 10]}
      textAlign={['center', 'center', 'left']}
      mb={[2, 3, 4]}
    >
      <Title color="blue">{title}</Title>
      <GridFlex>
        {attributes.map((attribute, index) => (
          <Box
            key={attribute.title}
            width={[1]}
            fontSize={[0, 1, 1]}
          >
            <Box
              px={0}
              width={[1, 1, '360px']}
              ml={[0, 0, `${10 * index}%`]}
              mb={[3, 4, 4]}
              textAlign={['center', 'center', 'left']}
            >
              <strong>{attribute.title}</strong>
              <div>{attribute.text}</div>
            </Box>
          </Box>
        ))}
        <Box
          ml={[0, 0, `${10 * 5}%`]}
          mx={['auto', 'auto', 'left']}
        >
          <Button {...button} />
        </Box>
      </GridFlex>
    </Box>
  </HomePageContainer>
);

HomePageWhyUs.propTypes = {
  title: PropTypes.string,
  movie: PropTypes.string,
  button: PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.oneOf(['red', 'blue', 'yellow']),
    center: PropTypes.bool,
    path: PropTypes.string,
  }),
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  image: PropTypes.shape({
    id: PropTypes.string,
    alt: PropTypes.string,
    src: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
  }),
  imageLarge: PropTypes.shape({
    id: PropTypes.string,
    alt: PropTypes.string,
    src: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
  }),
};

export default HomePageWhyUs;
