import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '../index';

const InfoPanelCheckbox = ({
  name,
  value,
  disabled,
  label,
  item = {},
  paid,
  hasItem,
  hasVacancy,
  onChange,
}) => {
  const getHasAdditionalLabel = () => {
    const { vacancies } = item;
    const availableVacancies = hasVacancy ? ` - ${vacancies} miejsc` : '';

    // TODO: messages
    if (!item.participant) return '';
    if (paid) return ' (Zakupiono)';
    if (!hasVacancy) return ' (Brak miejsc)';
    if (hasItem) return ' (Dodano do koszyka)';
    if (item.from_now) return ` (od dziś) ${availableVacancies}`;

    return availableVacancies;
  };

  const additionalLabel = getHasAdditionalLabel();

  return (
    <Checkbox
      name={name}
      small
      value={value}
      disabled={disabled}
      onChange={onChange}
      render={() => (
        <>
          {label}
          <strong>{additionalLabel}</strong>
        </>
      )}
    />
  );
};

InfoPanelCheckbox.propTypes = {
  onChange: PropTypes.func,
  hasItem: PropTypes.bool,
  paid: PropTypes.bool,
  hasVacancy: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  item: PropTypes.shape({}),
};

export default InfoPanelCheckbox;
