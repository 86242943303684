import { createGlobalStyle } from 'styled-components';
import { transparentize } from 'polished';
import theme from './theme';

const GlobalStyles = createGlobalStyle`
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: ${theme.font.family};
  font-weight: 400;
  font-size: ${theme.font.size};
  line-height: ${theme.font.lineHeight};
  color: ${theme.colors.text};
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  word-wrap: break-word;
  margin: 0;
  padding: 0;
  height: 100%;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

em {
  color: ${theme.colors.red};
  font-style: normal;
  font-weight: bold;
}

a,
.link {
  transition: ${theme.transition.set('color')}, ${theme.transition.set('background')}, ${theme.transition.set('border')};
  text-decoration: none;
  border-bottom: 1px dashed ${theme.colors.blue};
  cursor: pointer;

  &, &:visited, &:active {
    color: ${theme.colors.blue};
  }

  &:focus {
    outline:none;
  }

  &::-moz-focus-inner {
    border: none;
  }

  &:hover {
    color: ${theme.colors.blue};
    border-bottom-style: solid;
    background: ${theme.colors.blueLight};
  }
}

.link.red {
  border-bottom-color: ${theme.colors.red};
  color: ${theme.colors.red};
  opacity: 0.7;

  &:hover {
    color: ${theme.colors.red};
    border-bottom-color: ${theme.colors.red};
    background: ${theme.colors.redLight};
  }
}

table {
  border-collapse: collapse;
  th {
    font-weight: 600;
  }
  &, th, td {
    border: 1px solid ${theme.colors.border};
    padding: 0.5em;
    text-align: center;
  }
  tr:nth-child(2n) {
    background-color: ${theme.colors.lightBackground};
  }
}

blockquote {
  border-radius: ${theme.radius.normal};
  background-color: ${transparentize(0.95, theme.colors.yellow)};
  color: ${transparentize(0.1, theme.colors.text)};
  border-left: 3px solid ${transparentize(0.5, theme.colors.yellow)};
  margin: 0;
  padding: 1.5em;
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 1em;
  }

  &:after {
      content: '‘‘';
      font-family: serif;
      display: block;
      font-size: 16em;
      color: ${transparentize(0.9, theme.colors.yellow)};
      position: absolute;
      top: 0;
      left: 12px;
      z-index: -1;
      line-height: 0.8;
      letter-spacing: -12px;
  }
}

button {
  font: inherit;
}

input,
select {
  box-sizing : border-box;
  width: 100%;
  border-radius: ${theme.radius.normal};
  padding: 0.5em 1em;
  border: 1px solid ${theme.colors.border};
  transition: ${theme.transition.set('border-color')};
  font: inherit;
  font-size: inherit;
  background: white;

  ::placeholder {
    color: ${theme.colors.textLight};
  }

  &:focus {
    border-color: ${theme.colors.blue};
    outline: none;
  }

  &.error {
    border-color: ${theme.colors.red};
  }
}

label {
  display: block;
}

hr {
  border-style: solid;
  border-color: ${theme.colors.lightBackground};
  margin: 1em 0;
  border-width: 1px;
}

p {
  margin: 1em 0;

  &:first-child {
      margin-top: 0;
  }
  &:last-child {
      margin-bottom: 0;
  }
  &:only-child {
      margin: 0;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 1.4;
  color: ${theme.colors.red};
}

h4 {
  font-weight: 600;
}

strong {
  margin: 0;
  font-weight: 600;
}

h1 {
  font-size: ${theme.font.h1Size};
}

h2 {
  font-size: ${theme.font.h2Size};
}

h3 {
  font-size: ${theme.font.h3Size};
}

h4 {
  font-size: ${theme.font.h4Size};
}

h5 {
    font-size: ${theme.font.h5Size};
}

h6 {
    font-size: ${theme.font.h6Size};
}

::selection {
  background: ${transparentize(0.6, theme.colors.yellowLight)};
  color: ${theme.colors.text};
}

// scrollbar
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  // background-color: transparent;
}
::-webkit-scrollbar-track:hover {
  background-color: ${theme.colors.blueLight};
}

::-webkit-scrollbar-thumb {
  background-color: ${theme.colors.border};
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: ${theme.colors.textLight};
}

::-webkit-scrollbar-button {
  display: none;
}

* {
  scrollbar-color: ${theme.colors.textLight} transparent;
  scrollbar-width: thin;
}

`;

export default GlobalStyles;
