import styled from 'styled-components';

const CalendarRow = styled.div`
    display: flex;
    flex-direction: row;

    ${(props) => props.last && `
        height: 0;
    `}
`;

export default CalendarRow;
