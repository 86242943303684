import styled from 'styled-components';
import { maxWidth, position, textAlign } from 'styled-system';
import Box from './Box';
import theme from '../styled/theme';

const Container = styled(Box)`
  width: 100%;
  ${maxWidth};
  ${position};
  ${textAlign};
`;

Container.defaultProps = {
  mx: 'auto',
  py: [3, 4],
  px: [3, 4],
  fontSize: [0, 1, 2],
  maxWidth: theme.widths.normal,
};

export default Container;
