import React from 'react';
import PropTypes from 'prop-types';
import config from '@data/config.json';
import styled from 'styled-components';
import Link from 'gatsby-link';
import {
  theme,
  Container,
  Flex,
  Button,
} from '@components';
import Box from './Grid/Box';

const Wrapper = styled(Box)`
  background-color: ${theme.colors.blueLight};
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.1);
`;

const {
  cookies: {
    text,
    agreeButton,
    infoButton,
    infoPath,
  } = {},
} = config;

const CookieBar = ({ onClick }) => (
  <Wrapper
    position="fixed"
    top="auto"
    left={0}
    bottom={0}
    right={0}
    width="100%"
  >
    <Container
      maxWidth={theme.widths.small}
      fontSize={[0, 1]}
      py={[2, 3]}
    >
      <Flex alignItems="center" flexDirection={['column', 'column', 'initial']}>
        <Box
          width={[1, 1, 4 / 5]}
          textAlign={['center', 'center', 'right']}
        >
          {text}
          {' '}
          <Link to={infoPath}>{infoButton}</Link>
        </Box>
        <Box
          width={[1, 1, 1 / 5]}
          textAlign={['center', 'center', 'left']}
          ml={[0, 0, 4]}
        >
          <Button
            color="red"
            py={[theme.button.small.py, theme.button.small.py, theme.button.big.small]}
            text={agreeButton}
            onClick={(event) => { onClick(event); }}
          />
        </Box>
      </Flex>
    </Container>
  </Wrapper>
);

CookieBar.defaultProps = {
  onClick: (event) => { event.preventDefault(); },
};

CookieBar.propTypes = {
  onClick: PropTypes.func,
};

export default CookieBar;
