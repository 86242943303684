import React from 'react';
import PropTypes from 'prop-types';
import MESSAGES from '@messages';

const VoucherRow = ({ voucher }) => {
  if (!voucher) return null;

  const { code, amount = 0 } = voucher || { amount: 0, code: '' };
  if (!code && amount >= 0) return null;
  return (
    <tr>
      <td>
        <strong>
          {MESSAGES.VOUCHER}
          :
        </strong>
      </td>
      <td>
        {code}
      </td>
      <td>
        <strong>
          {amount}
          {' '}
          {MESSAGES.PLN}
        </strong>
      </td>
      <td />
    </tr>
  );
};

VoucherRow.propTypes = {
  voucher: PropTypes.shape({
    code: PropTypes.string,
    amount: PropTypes.number,
  }),
};

export default VoucherRow;
