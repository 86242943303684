import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, emailValidator } from '@loform/react';
import axios from 'axios';
import {
  Button,
  Box,
  GridFlex,
  Loader,
  StaticError,
  StaticSuccess,
  TextInput,
  RodoCheckbox,
} from '@components';
import MESSAGES from '@messages';

const SUCCESS_MAP = {
  already_exists: MESSAGES.EMAIL_ALREADY_EXISTS,
  ok: MESSAGES.EMAIL_SUCCESSFULLY_ADDED,
};

class NewsletterForm extends Component {
    state = {
      isLoading: false,
      success: '',
      error: '',
    }

    clearDebounce = () => {
      clearTimeout(this.loaderDebounce);
    }

    setMessages = (messages = {}) => {
      this.clearDebounce();
      this.setState({
        success: '',
        error: '',
        isLoading: false,
        ...messages,
      });
    }

    setDefaultError = () => {
      this.setMessages({ error: MESSAGES.DEFAULT_ERROR });
    }

    handleSubmit = (values) => {
      this.loaderDebounce = setTimeout(() => {
        this.setState({ isLoading: true });
      }, 100);

      axios
        .post('newsletters/add', values)
        .then(({ status, data = {} }) => {
          if (status === 200 && data.ok) {
            this.clearDebounce();
            this.setState({
              success: SUCCESS_MAP.ok,
              error: '',
              isLoading: false,
            });
          } else {
            this.setDefaultError();
          }
        }).catch(({ response: { data: { message = '' } = {} } = {} } = {}) => {
          const success = SUCCESS_MAP[message];
          if (success) {
            this.setMessages({ success });
          } else {
            this.setDefaultError();
          }
        });
    }


    render() {
      const { button } = this.props;
      const { isLoading, success, error } = this.state;

      return (
        <Form
          className="form"
          onSubmit={this.handleSubmit}
        >
          {({ submit, errors, isValidating }) => (
            <GridFlex p={[2, 3, 4]} position="relative">
              {(isLoading || isValidating) && <Loader />}
              <Box
                display="flex"
                justifyContent={['center', 'center', 'flex-end']}
                width={[1, 1, 1 / 2]}
                pr={[0, 0, 3]}
              >
                <TextInput
                  errors={errors}
                  name="name"
                  placeholder={MESSAGES.FIRST_NAME}
                  required
                />
              </Box>
              <Box
                display="flex"
                justifyContent={['center', 'center', 'flex-start']}
                width={[1, 1, 1 / 2]}
                mt={[3, 4, 0]}
              >
                <TextInput
                  errors={errors}
                  name="email"
                  placeholder={MESSAGES.EMAIL_ADDRESS}
                  validators={[emailValidator(MESSAGES.WRONG_EMAIL)]}
                  required
                  requiredMessage={MESSAGES.EMAIL_REQUIRED}
                />
              </Box>
              <Box
                pt={[3, 3, 4]}
                width={1}
                textAlign="center"
              >
                <RodoCheckbox errors={errors} />
                <Button
                  {...button}
                  onClick={(e) => {
                    e.preventDefault();
                    submit();
                  }}
                  type="submit"
                  Tag="button"
                />
              </Box>
              {error && <StaticError width={[1, 1, 1, 1 / 2]}>{error}</StaticError>}
              {success && <StaticSuccess width={[1, 1, 1, 1 / 2]}>{success}</StaticSuccess>}
            </GridFlex>
          )}
        </Form>
      );
    }
}

NewsletterForm.propTypes = {
  button: PropTypes.shape({}),
};

export default NewsletterForm;
