import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CheckboxInput } from '@loform/react';
import { Box } from '@components';
import MESSAGES from '@messages';
import theme from './styled/theme';
import SquareIconBg from '../assets/icons/square.svg';
import CheckSquareIconBg from '../assets/icons/check-square.svg';

const size = theme.font.h3Size;
const smallSize = theme.font.h4Size;

const StyledErrors = styled.div`
  margin-left: 0.5em;
  font-size: 0.7em;
`;

const hasErrors = (errors, inputName) => !!(errors[inputName] && errors[inputName].length);
const getErrorClass = (errors, inputName) => hasErrors(errors, inputName) ? 'error' : '';
const renderErrors = (errors, inputName) => hasErrors(errors, inputName) && (
  <StyledErrors>
    {errors[inputName].map((error) => <div key={error}>{error}</div>)}
  </StyledErrors>
);

const StyledWrapperBox = styled(Box)`
  user-select: none;

  &.inline {
    display: inline-block;
  }

  &:not(.inline) + &:not(.inline) {
    padding-top: 0;
    margin-top: -0.5em;
  }
`;

const StyledCheckbox = styled(CheckboxInput)`
  display: inline-block;
  width: ${size};
  height: ${size};
  margin: 0 0.5em 0 0;
  opacity: 0;

  label &:disabled + .checkbox-icon {
    &,
    &:hover,
    &:focus {
      opacity: 0.5;
    }
  }

  label &:disabled:checked + .checkbox-icon {
    &,
    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }

  &:checked + .checkbox-icon {
    background-image: url(${CheckSquareIconBg});
    opacity: 1;
  }
`;

const Label = styled.label`
  display: inline-block;
  align-items: center;
  text-align: left;
  border-radius: ${theme.radius.normal};
  cursor: pointer;
  position: relative;

  &:hover,
  &:focus {
    .checkbox-icon {
      opacity: 1;
    }
  }


  ${(props) => props.render && `
    display: block;
  `}

  ${(props) => props.small && `
    font-size: 0.7em;
    line-height: 1.5;

    .checkbox-icon {
      width: ${smallSize};
      height: ${smallSize};
      background-size: ${smallSize};
      top: 10px;
    }
  `}

  ${(props) => props.disabled && `
    color: ${theme.colors.textLight};
    cursor: not-allowed;
  `}

  &.error {
    background: ${theme.colors.redLight};
  }
`;

const Info = styled.span`
  display: inline-block;
  font-size: 0.8em;
  margin-left: 0.5em;
`;

const YellowInfo = styled(Info)`
  color: ${theme.colors.yellow};
`;

const ErrorMessage = styled.div`
  color: ${theme.colors.red};
  font-size: 1rem;
`;

const Icon = styled.span`
  display: inline-block;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 0;

  background-image: url(${SquareIconBg});
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: ${size};
  width: ${size};
  height: ${size};
  transition: ${theme.transition.set('opacity')};
  opacity: 0.6;
`;

const Checkbox = ({
  errors = {},
  required,
  requiredMessage,
  name,
  disabled,
  label,
  info,
  yellowInfo,
  render,
  small,
  inline,
  ...props
}) => {
  const renderLabel = typeof render === 'function' && render();
  return (
    <StyledWrapperBox
      position="relative"
      mb={1}
      p={[1, 2]}
      className={inline ? 'inline' : ''}
    >
      <Label
        render={render}
        disabled={disabled}
        className={getErrorClass(errors, name)}
        small={small}
      >
        <StyledCheckbox
          {...props}
          name={name}
          disabled={disabled}
          className={getErrorClass(errors, name)}
          required={!disabled && required}
          requiredMessage={requiredMessage || (required ? MESSAGES.DEFAULT_REQUIRED_MESSAGE : '')}
        />
        <Icon className="checkbox-icon" />
        {label}
        {renderLabel}
        {info && <Info>{info}</Info>}
        {yellowInfo && <YellowInfo>{yellowInfo}</YellowInfo>}
        <ErrorMessage>{renderErrors(errors, name)}</ErrorMessage>
      </Label>
    </StyledWrapperBox>
  );
};

Checkbox.propTypes = {
  errors: PropTypes.shape({}),
  required: PropTypes.bool,
  requiredMessage: PropTypes.string,
  yellowInfo: PropTypes.string,
  info: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  label: PropTypes.string,
  validators: PropTypes.arrayOf(PropTypes.shape({})),
  render: PropTypes.func,
  small: PropTypes.bool,
  inline: PropTypes.bool,
};

export default Checkbox;
