import React from 'react';
import PropTypes from 'prop-types';
import { SEO } from '@components';
import {
  HomePageHero,
  HomePageWhyUs,
  HomePageOffer,
  HomePageArt,
  HomePageNewsletter,
} from './HomePage';

const HomePageTemplate = ({
  hero,
  whyUs,
  offer,
  art,
  newsletter,
  seo,
}) => (
  <>
    <SEO {...seo} />
    <HomePageHero {...hero} />
    <HomePageWhyUs {...whyUs} />
    <HomePageOffer {...offer} />
    <HomePageArt {...art} />
    <HomePageNewsletter {...newsletter} />
  </>
);

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  hero: PropTypes.object,
  whyUs: PropTypes.object,
  offer: PropTypes.object,
  newsletter: PropTypes.object,
  art: PropTypes.object,
  seo: PropTypes.shape({
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
  }),
};

export default HomePageTemplate;
