import {
  darken, mix, transparentize,
} from 'polished';

const colors = {
  primary: '#ff444c',
  secondary: '#5e2588',
  third: '#c52686',
  text: '#525F7F',
  textLight: transparentize(0.33, '#525F7F'),
  lightBackground: darken(0.025, 'white'),
  border: '#d0d5e1',
  red: '#ff6f75',
  redLight: '#ffe6e7',
  green: '#27AE60',
  greenLight: transparentize(0.8, '#6FCF97'),
  blue: '#69AACE',
  blueLight: '#EBF4F9',
  yellow: '#F9B906',
  yellowLight: '#FEECB8',
  bg: '#fff',
};

colors.background = {
  blue: transparentize(0.6, colors.blueLight),
};

colors.button = {
  red: {
    background: colors.red,
    color: colors.bg,
    shadow: 'rgba(255, 79, 85, 0.33)',
  },
  blue: {
    background: colors.blueLight,
    color: colors.blue,
    shadow: 'rgba(105, 170, 206, 0.26)',
    borderColor: mix(0.8, colors.blueLight, colors.blue),
  },
  yellow: {
    background: colors.yellowLight,
    color: colors.text,
    shadow: '#FEECB8',
    borderColor: mix(0.95, colors.yellowLight, colors.text),
  },
};

const panel = {
  width: '300px',
  smallHeight: '420px',
  height: '550px',
};

const widths = {
  small: 960,
  normal: 1440,
  full: 'initial',
};

const button = {
  small: {
    py: '0.5em',
    px: '1em',
    fontSize: '0.75em',
  },
  big: {
    py: '0.75em',
    px: '2em',
  },
};

colors.darkBackground = mix(0.4, colors.secondary, 'black');
colors.darkText = mix(0.4, colors.text, 'black');

const calendar = {
  height: '2.5rem',
  headerHeight: '5rem',
  eventPadding: '3px',
  minWidth: '940px',
  colors: {
    // domyślny (pomarańczowy),
    0:  '#FEE2CD',
    // niebieski,
    1:  '#DBF0FF',
    // zielony,
    2:  '#E7FADB',
    // fioletowy,
    3:  '#ECDFF6',
    // różowy,
    4:  '#F9DDED',
    // żółty,
    5:  colors.yellowLight,
    // czerwony,
    6:  '#FADCDC',
    // jasnoniebieski,
    7:  colors.blueLight,
    // szary,
    8:  '#E8E8E8',
    // ciemnoniebieski,
    9:  '#D0D9F5',
    // ciemnozielony,
    10: '#C9EDD8',
    // ciemnoczerwony,
    11: '#F2E5DE',
  },
};

const transition = {
  easing: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
  duration: '.15s',
};

transition.set = (
  property,
  duration = transition.duration,
  easing = transition.easing,
  delay = '0s'
) => `${property} ${duration} ${easing} ${delay}`;

const font = {
  family: '-apple-system, BlinkMacSystemFont, Open Sans, Segoe UI, Helvetica Neue, Helvetica, Arial, sans-serif',
  size: '18px',
  lineHeight: 2,
  h1Size: '2.441rem',
  h2Size: '1.953rem',
  h3Size: '1.563rem',
  h4Size: '1.25rem',
  h5Size: '0.8rem',
  h6size: '0.64rem',
};

const radius = {
  small: '0.25em',
  normal: '0.5em',
};

const shadow = '0 6px 80px -15px rgba(13, 13, 76, 0.1)';

// Variants
const listVariants = {
  inline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};

const fontSizes = [14, 16, 18, 20, 22, 28, 32, 48, 64, 72];

export default {
  colors,
  transition,
  font,
  radius,
  shadow,
  listVariants,
  fontSizes,
  button,
  widths,
  calendar,
  panel,
};
