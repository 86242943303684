import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MESSAGES from '@messages';
import { theme } from './styled';

const StyledDiv = styled.div`
  text-align: center;
  font-size: 1.25em;

  span {
    color: ${theme.colors.primary};
  }

  span.small {
    font-size: 0.8rem;
    color: ${theme.colors.textLight};
  }
`;

const Amount = ({ children }) => {
  if (!children) return null;

  return (
    <StyledDiv>
      {MESSAGES.FULL_PRICE}
      :
      {' '}
      <span>
        {children}
        {' '}
        {MESSAGES.PLN}
      </span>
    </StyledDiv>
  );
};


Amount.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Amount;
