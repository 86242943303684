import React from 'react';
import PropTypes from 'prop-types';
import { Img, GridBox } from '@components';

const HomePageImage = ({ image, ...rest }) => (
  <GridBox
    style={{
      zIndex: -1,
    }}
    width={[1, 1, 1 / 3]}
    py={0}
    px={null}
    pl={[0, 0, 2, 3]}
    {...rest}
  >
    <Img {...image} />
  </GridBox>
);

HomePageImage.propTypes = {
  image: PropTypes.shape({}),
};

export default HomePageImage;
