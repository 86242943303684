import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import config from '../../data/config.json';

const SEO = ({ metaTitle, metaDescription }) => (
  <Helmet>
    <title>{metaTitle}</title>
    <meta
      name="description"
      content={metaDescription || config.siteDescription}
    />
  </Helmet>
);

SEO.propTypes = {
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
};

export default SEO;
