import React, { Component } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';
import {
  Container,
  Hero,
  Box,
  Title,
  GridFlex,
} from '@components';
import MESSAGES from '@messages';
import theme from '../styled/theme';

const SectionWrapper = styled.div`
  ${(props) => props.index % 2 !== 0 && `
    background: ${theme.colors.background.blue};
  `}
`;

const TextInput = styled.input`
  width: 100%;
  padding: 0.5em 1em;
  margin-bottom: 1em;
  text-align: center;
`;


const RadioButton = styled.label`
  cursor: ${(props) => props.active ? 'initial' : 'pointer'};
  padding: 0.25em 1em;
  border-radius: 999px;
  border: 2px solid transparent;
  background-color: ${(props) => props.active ? theme.colors.yellowLight : 'transparent'};
  transition: ${theme.transition.set('background')}, ${theme.transition.set('border')};

  &:hover {
    background-color: ${(props) => props.active ? theme.colors.yellowLight : transparentize(0.6, theme.colors.yellowLight)};
  }

  &:hover,
  &:focus {
    border-color: ${theme.colors.yellowLight};
  }

  [type=radio] {
    width: 0;
    height: 0;
    border: 0;
    position: absolute;
    overflow: hidden;
    opacity: 0;
  }
`;

class FaqPageTemplate extends Component {
  state = {
    search: '',
    chosenCategory: MESSAGES.ALL_CATEGORIES,
  }

  handleSearchChange = ({ target: { value } }) => {
    this.setState({
      search: value,
    });
  }

  handleCategoryChange = ({ target: { value } }) => {
    this.setState((prevState) => prevState.chosenCategory === value
      ? null
      : { chosenCategory: value });
  }

  render() {
    const {
      hero,
      seo,
      categories,
    } = this.props;
    const { search, chosenCategory } = this.state;

    const filteredCategories = categories
      .filter(({ title }) => !chosenCategory || [title, MESSAGES.ALL_CATEGORIES].includes(chosenCategory))
      .map(({ items, ...rest }) => {
        const filteredItems = items
          .filter(({ question, answer }) => {
            const s = search.toLowerCase();
            return question.toLowerCase().includes(s) || answer.toLowerCase().includes(s);
          });
        return { ...rest, items: filteredItems };
      });

    return (
      <>
        <Hero {...hero} seo={seo} />
        <Container maxWidth={theme.widths.small}>
          <GridFlex>
            <TextInput
              value={search}
              onChange={this.handleSearchChange}
              placeholder={MESSAGES.WHAT_ARE_YOURE_LOOKING_FOR}
            />
            <Box
              width="100%"
            >
              <GridFlex justifyContent="space-between" fontSize={1}>
                {[{ title: MESSAGES.ALL_CATEGORIES }, ...categories].map(({ title }) => (
                  <RadioButton
                    key={title}
                    active={title === chosenCategory}
                  >
                    <input
                      type="radio"
                      value={title}
                      name="category"
                      onChange={this.handleCategoryChange}
                    />
                    {title}
                  </RadioButton>
                ))}
              </GridFlex>
            </Box>
          </GridFlex>
        </Container>
        {filteredCategories.some(({ items }) => items.length <= 0)
          ? (
            <Container maxWidth={theme.widths.small}>
              <Box
                width={1}
                fontSize={4}
                textAlign="center"
                mx="auto"
              >
                {MESSAGES.NO_RESULTS}
              </Box>
            </Container>
          )
          : filteredCategories
            .map(({ title, items, color }, index) => {
              if (!items.length) return null;
              return (
                <SectionWrapper
                  key={title}
                  index={index}
                >
                  <Container maxWidth={theme.widths.small}>
                    <Box width={1}>
                      <Title
                        tag="h4"
                        color={color}
                      >
                        {title}
                      </Title>
                    </Box>
                    {items.map(({ question, answer }) => (
                      <Box
                        key={question}
                        width={1}
                        mb={[1, 2, 3]}
                      >
                        <strong>{question}</strong>
                        <div>{answer}</div>
                      </Box>
                    ))}
                  </Container>
                </SectionWrapper>
              );
            })}
      </>
    );
  }
}

FaqPageTemplate.propTypes = {
  hero: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }),
  categories: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    color: PropTypes.oneOf(['red', 'blue', 'yellow']).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })),
  })),
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  contacts: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string,
    description: PropTypes.string,
  })),
  seo: PropTypes.shape({}),
};

export default FaqPageTemplate;
