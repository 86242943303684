import React from 'react';
import PropTypes from 'prop-types';
import {
  Title,
  Box,
  Markdown,
} from '@components';

import {
  HomePageContainer,
  HomePageImage,
} from './Components';

const HomePageArt = ({
  visible, title, markdown, image, imageLarge,
}) => {
  if (!visible) return null;

  return (
    <HomePageContainer>
      <HomePageImage
        image={image}
        width={1}
        display={['block', 'block', 'none']}
      />
      <HomePageImage
        image={imageLarge}
        order={[0, 0, 1]}
        display={['none', 'none', 'block']}
      />
      <Box
        width={[1, 1, 2 / 3]}
        textAlign={['center', 'center', 'initial']}
        pr={[null, null, 4]}
        p={[3, 4, null]}
      >
        <Title color="red">{title}</Title>
        <Markdown>{markdown}</Markdown>
      </Box>
    </HomePageContainer>
  );
};

HomePageArt.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  markdown: PropTypes.string,
  image: PropTypes.shape({
    id: PropTypes.string,
    alt: PropTypes.string,
    src: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
  }),
  imageLarge: PropTypes.shape({
    id: PropTypes.string,
    alt: PropTypes.string,
    src: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
  }),
};

export default HomePageArt;
