import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RadioInput } from '@loform/react';
import { Box } from '@components';
import MESSAGES from '@messages';
import theme from './styled/theme';

const StyledErrors = styled.div`
  margin-left: 0.5em;
  font-size: 0.7em;
`;

const hasErrors = (errors, inputName) => !!(errors[inputName] && errors[inputName].length);
const getErrorClass = (errors, inputName) => hasErrors(errors, inputName) ? 'error' : '';
const renderErrors = (errors, inputName) => hasErrors(errors, inputName) && (
  <StyledErrors>
    {errors[inputName].map((error) => <div key={error}>{error}</div>)}
  </StyledErrors>
);

const StyledRadio = styled(RadioInput)`
text-align: left;
border-radius: ${theme.radius.normal};
padding: 0.5em;
cursor: pointer;

${(props) => props.render && `
  display: block;
`}

${(props) => props.small && `
  font-size: 0.8em;
`}

${(props) => props.disabled && `
  color: ${theme.colors.textLight};
  cursor: not-allowed;
`}

&.error {
  background: ${theme.colors.redLight};
} 

& > div {
      display: inline-block;
      width: 100%;
      display: flex;
      align-items: center;

      & > input {
            margin-right: 0.5em;
            height: 1em;
            min-height: 1em;
            width: 1em;
            min-width: 1em;
        }
 }
`;

const Radio = ({
  errors = {},
  required,
  requiredMessage,
  name,
  disabled,
  label,
  info,
  yellowInfo,
  render,
  small,
  ...props
}) => {
  const renderLabel = typeof render === 'function' && render();
  return (
    <Box
      position="relative"
      mb={1}
    >
      <StyledRadio
        {...props}
        small={small}
        className={getErrorClass(errors, name)}
        name={name}
        disabled={disabled}
        required={!disabled && required}
        requiredMessage={requiredMessage || (required ? MESSAGES.DEFAULT_REQUIRED_MESSAGE : '')}
      />
      {label}
      {renderLabel}
      {renderErrors(errors, name)}
    </Box>
  );
};

Radio.propTypes = {
  errors: PropTypes.shape({}).isRequired,
  required: PropTypes.bool,
  requiredMessage: PropTypes.string,
  yellowInfo: PropTypes.string,
  info: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.number,
  label: PropTypes.string,
  small: PropTypes.bool,
  render: PropTypes.func,
  validators: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Radio;
