import MESSAGES from '@messages';

const passwordLengthValidator = (errorMessage = MESSAGES.PASSWORD_TOO_SHORT_ERROR) => ({
  errorMessage,
  validate: (value) => {
    if (!value) return true;

    return value.length > 6;
  },
});


export default passwordLengthValidator;
