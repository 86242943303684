import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@components';
import dayjs from 'dayjs';
import { DAY_NAMES_SHORT } from '@constants';
import { UserContext, updateTimeDST } from '@helpers';
import MESSAGES from '@messages';
import { theme } from '../styled';

const PurchasesForm = ({ closePortal }) => {
  const { purchases } = useContext(UserContext);

  return (
    <>
      <Box p={0} position="relative">
        <Box>
          {purchases.length ? purchases.map(({ event, participant, ...purchase }) => (
            <div key={purchase.id} data-id={purchase.id}>
              <h4 style={{ textTransform: 'none', letterSpacing: 0 }}>
                {`${event.name} ${DAY_NAMES_SHORT[event.weekday].toLowerCase()} ${updateTimeDST(event.startTime)} - ${updateTimeDST(event.endTime)}`}
                <span style={{ color: 'initial' }}>
                  {' - '}
                  {participant.name}
                </span>
              </h4>
              <div>
                Prowadzący:
                {' '}
                {event.hosts.map((host) => host.name).join(', ')}
              </div>
              <h5 style={{ color: theme.colors.blue }}>Płatności:</h5>
              <div>
                {purchase.payments.map((payment) => {
                  const isPaid = payment.status === 'paid';
                  return (
                    <>
                      <div>
                        {dayjs(payment.date).format('DD.MM.YYYY HH:mm')}
                        {', '}
                        {payment.amount}
                        zł
                        {' '}
                        {' - '}
                        <span style={{ color: isPaid ? theme.colors.green : theme.colors.red, opacity: 0.5 }}>
                          {isPaid ? 'opłacono' : 'nieopłacono'}
                        </span>
                      </div>
                    </>
                  );
                })}
              </div>
              <hr />
            </div>
          )) : (
            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '3em auto',
            }}
            >
              <h4>{MESSAGES.NO_PURCHASES}</h4>
            </div>
          )}
          {/* <pre>{JSON.stringify(purchases, null, 2)}</pre> */}
        </Box>
      </Box>
    </>
  );
};

PurchasesForm.propTypes = {
  closePortal: PropTypes.func,
};

export default PurchasesForm;
