export function isValidDate(str = '') {
  if (str == null || typeof str === 'number') return false;
  const isString = typeof str === 'string';
  if (!isString && !(str instanceof Date)) return false;
  const dateNum = new Date(str).getTime();
  const isInvalidDate = isNaN(dateNum);
  if (isInvalidDate) return false;
  // TODO: maybe it's better way to do this
  if (isString && !/\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}.\d{3}Z)?/.test(str)) return false;
  const d = new Date(str);
  return d instanceof Date && !isNaN(+d) ? true : d?.toISOString() === str;
}
