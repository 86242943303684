import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { display as displayVariant } from 'styled-system';
import { theme } from '@components';
import { Triangle, Circle, Square } from './AllShapes';

const Wrapper = styled.div`
  position: absolute;
  z-index: -1;
  color: ${(props) => props.color};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  ${displayVariant};
`;

const TYPES = {
  triangle: Triangle,
  circle: Circle,
  square: Square,
};

const COLORS = {
  red: theme.colors.red,
  blue: theme.colors.blue,
  yellow: theme.colors.yellow,
};

const Shape = ({
  type,
  color,
  display,
  style: {
    top, left, bottom, right, ...style
  } = {},
  ...rest
}) => {
  const Component = TYPES[type];
  return (
    <Wrapper
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      color={COLORS[color]}
      display={display}
    >
      <Component style={style} {...rest} />
    </Wrapper>
  );
};

Shape.defaultProps = {
  type: 'triangle',
  color: 'yellow',
};

Shape.propTypes = {
  style: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  type: PropTypes.oneOf(Object.keys(TYPES)),
  color: PropTypes.oneOf(Object.keys(COLORS)),
  display: PropTypes.string,
};

export default Shape;
