import { style } from 'styled-system';

export const left = style({
  prop: 'left',
  cssProperty: 'left',
});


export const right = style({
  prop: 'right',
  cssProperty: 'right',
});


export const bottom = style({
  prop: 'bottom',
  cssProperty: 'bottom',
});


export const top = style({
  prop: 'top',
  cssProperty: 'top',
});


export const opacity = style({
  prop: 'opacity',
  cssProperty: 'opacity',
});
