import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styled from 'styled-components';
import { auth, CartContext, UserContext } from '@helpers';
import MESSAGES from '@messages';

const label = 'Wybierz klienta';

const StyledLabel = styled.label`
  font-size: 0.8em;
  flex: 1;
  margin-right: 0.5em;
`;

const SmallText = styled.span`
`;

const CashClientsSelect = ({
  initialValue = {},
  onChange,
  cashClients,
  isSuperUser,
  fetchClient,
}) => {
  const [selected, setSelected] = useState(initialValue);
  const { forceClearCart } = useContext(CartContext);
  const { clearUpcomming } = useContext(UserContext);

  useEffect(() => {
    if (onChange) onChange(selected);

    forceClearCart();
    // clearUpcomming();

    auth.setAsClientHeader(selected.value);
    // fetchClient();
  }, [selected, onChange, fetchClient, clearUpcomming, forceClearCart]);

  if (!isSuperUser || !cashClients) return null;

  return (
    <StyledLabel htmlFor="cash-clients">
      <SmallText>{label}</SmallText>
      <Select
        id="cash-clients"
        onChange={setSelected}
        value={selected}
        className={!selected.value ? 'error' : ''}
        noOptionsMessage={() => MESSAGES.NO_RESULTS}
        options={cashClients.map(({ id, name }) => ({
          label: name,
          value: id,
        }))}
      >
        <option value="">{label}</option>
      </Select>
    </StyledLabel>
  );
};

CashClientsSelect.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  cashClients: PropTypes.arrayOf(PropTypes.shape({})),
  isSuperUser: PropTypes.bool,
  fetchClient: PropTypes.func,
};

export default CashClientsSelect;
