import React from 'react';
import PropTypes from 'prop-types';
import MESSAGES from '@messages';
import { getInstructorsString, getLocationString } from '@helpers';

const CartClassesCell = ({
  name = '',
  day_index = 0,
  instructors = [''],
  start_time = '',
  end_time = '',
  month,
  location,
}) => (
  <>
    <div>
      <strong>{name}</strong>
      {typeof month === 'number' ? ` (${MESSAGES.MONTH_NAMES[month]})` : ''}
    </div>
    <div>
      {`${MESSAGES.DAY_NAMES[day_index]} ${start_time} - ${end_time}`}
    </div>
    {instructors.length && (
      <div>
        {getInstructorsString(instructors)}
      </div>
    )}
    {getLocationString(location)}
  </>
);

CartClassesCell.propTypes = {
  name: PropTypes.string,
  location: PropTypes.object,
  day_index: PropTypes.number,
  month: PropTypes.number,
  instructors: PropTypes.arrayOf(PropTypes.string),
  start_time: PropTypes.string,
  end_time: PropTypes.string,
};

export default CartClassesCell;
