import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { UserContext } from '@helpers';
import MESSAGES from '@messages';
import { ParticipantForm } from './Forms';
import { Portal } from './Portals';

const addValue = 'add-aprticipant';
const label = 'Wybierz uczestnika';

const SmallText = styled.span`
  font-size: 0.8em;
`;

const ParticipantsSelect = ({
  initialValue = '', onChange,
}) => {
  const { client } = useContext(UserContext);
  const [value, setValue] = useState(initialValue);
  const { participants = [] } = client || {};
  const { length } = participants;

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value, onChange]);

  if (!client) return null;

  return (

    <Portal
      title={MESSAGES.ADDING_PARTICIPANT_TITLE}
      body={({ closePortal }) => <ParticipantForm closePortal={closePortal} setValue={setValue} />}
    >
      {({ openPortal }) => (
        <label htmlFor="participants">
          <SmallText>{label}</SmallText>
          <select
            id="participants"
            onChange={(e) => {
              if (e.target.value === addValue) {
                openPortal();
                setValue('');
              } else {
                const nextValue = e.target.value;
                setValue(nextValue);
              }
            }}
            value={value}
            className={!value ? 'error' : ''}
          >
            <option value="">{label}</option>
            {participants.map(({ id, name }) => (
              <option key={id} value={id}>{name}</option>
            ))}
            {length < 3 && <option value={addValue}>{MESSAGES.ADD_PARTICIPANT}</option>}
          </select>
        </label>
      )}

    </Portal>
  );
};

ParticipantsSelect.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
};

export default ParticipantsSelect;
