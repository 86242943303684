import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/no-danger */
const Markdown = ({ children, className }) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{ __html: children }}
  />
);
/* eslint-enable react/no-danger */

Markdown.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
};

export default Markdown;
