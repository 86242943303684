import styled from 'styled-components';
import {
  textAlign, display, position, space, maxWidth, width, maxHeight, fontSize, alignItems, justifyContent, flexDirection,
} from 'styled-system';
import {
  top, left, bottom, right, opacity,
} from './styled/styled-system';

const Table = styled.table`
  ${display};
  ${position};
  ${width};
  ${space};
  ${maxWidth};
  ${maxHeight};
  ${textAlign};
  ${fontSize};
  ${top};
  ${left};
  ${bottom};
  ${right};
  ${opacity};
  ${alignItems};
  ${justifyContent};
  ${flexDirection};
`;

Table.defaultProps = {
  width: 1,
  mx: 'auto',
};

export default Table;
