import React from 'react';
import styled from 'styled-components';
import { theme } from '@components';

import { transparentize } from 'polished';

const Backdrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: ${transparentize(0.6, '#fff')};
    z-index: 1;
    cursor: wait;
`;

const AnimatedLoader = styled.div`
  border: 5px solid ${theme.colors.red};
  border-radius: 30px;
  height: 30px;
  left: 50%;
  margin: -15px 0 0 -15px;
  opacity: 0;
  position: absolute;
  top: 50%;
  width: 30px;

  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;

  @keyframes pulsate {
    0% {
      transform: scale(.1);
      opacity: 0.0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
`;

const Loader = () => (
  <Backdrop>
    <AnimatedLoader />
  </Backdrop>
);

export default Loader;
