import axios from 'axios';
import { auth } from '@helpers';
import { API_URL } from '@constants';

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['X-Api-Key'] = 'eztYMeOXmyeuhpRUr6cCVZj';
axios.defaults.withCredentials = true;
axios.defaults.credentials = 'include';

const token = auth.getToken();
if (token) auth.setAxiosHeaders(token);
