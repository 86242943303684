import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const height = 16;

const defs = {
  heightIcon: height,
  widthLine: height * 2,
  heightLine: 2,
  transitionTime: '0.4s',
  rotation: '45deg',
  translateY: `${height / 2}px`,
  translateX: 0,
  lineColor: 'currentColor',
};

const HamburgerIcon = styled.div`
  cursor: pointer;
  padding: 1em 0 1em 0.5em;
  position: relative;
  z-index: 5;
  transition: transform ${defs.transitionTime}, opacity ${defs.transitionTime};
  opacity: 0.6;

  &:hover, &:focus {
    opacity: 1;
  }

  #hamburgerIcon {
    width: ${defs.widthLine}px;
    height: ${defs.heightIcon}px;
    position: relative;

    .line {
      display:block;
      background: ${defs.lineColor};
      width: ${defs.widthLine}px;
      height: ${defs.heightLine}px;
      position:absolute;
      left:0;
      border-radius: ${defs.heightLine / 2}px;
      transition: all ${defs.transitionTime};

      &.line:nth-child(1) {
        top:0;
      }
      &.line:nth-child(2) {
        top:50%;
      }
      &.line:nth-child(3) {
        top:100%;
        width: 50%;
      }
    }
  }

  &.active {
    /* transform: translateX(50%); */

    #hamburgerIcon {
      .line:nth-child(1) {
        transform: translateY(${defs.translateY}) translateX(${defs.translateX}) rotate(${defs.rotation});
      }
      .line:nth-child(2) {
        opacity:0;
      }
      .line:nth-child(3) {
        transform: translateY(-${defs.translateY}) translateX(${defs.translateX}) rotate(-${defs.rotation});
        width: 100%;
      }
    }
  }
`;

const Line = () => <div className="line" />;

const Hamburger = ({ isActive, toggle }) => (
  <HamburgerIcon
    onClick={() => toggle()}
    className={isActive ? 'active' : ''}
  >
    <div id="hamburgerIcon">
      <Line />
      <Line />
      <Line />
    </div>
  </HamburgerIcon>
);

Hamburger.propTypes = {
  isActive: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};

export default Hamburger;
