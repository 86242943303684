import React from 'react';
import PropTypes from 'prop-types';

const defaultProps = {
  size: 100,
  strokeWidth: 5,
  rotate: '45',
  opacity: 0.2,
};

const propTypes = {
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  rotate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  opacity: PropTypes.number,
  style: PropTypes.shape({}),
};

const Triangle = ({
  size, strokeWidth, rotate, opacity, style,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 -5 100 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transformOrigin: 'center',
      transform: `rotate(${rotate}deg)`,
      style,
    }}
  >
    <polygon
      points="100,100 50,0 0,100"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      opacity={opacity}
    />
  </svg>
);

const Square = ({
  size, strokeWidth, rotate, opacity, style,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transformOrigin: 'center',
      transform: `rotate(${rotate}deg)`,
      style,
    }}
  >
    <rect
      x="5"
      y="5"
      width="90"
      height="90"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      opacity={opacity}
    />
  </svg>
);

const Circle = ({
  size, strokeWidth, rotate, opacity, style,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transformOrigin: 'center',
      transform: `rotate(${rotate}deg)`,
      style,
    }}
  >
    <circle
      cx="50"
      cy="50"
      r="45 "
      stroke="currentColor"
      strokeWidth={strokeWidth}
      opacity={opacity}
    />
  </svg>
);

Triangle.defaultProps = defaultProps;
Triangle.propTypes = propTypes;

Square.defaultProps = defaultProps;
Square.propTypes = propTypes;

Circle.defaultProps = defaultProps;
Circle.propTypes = propTypes;


export { Circle, Triangle, Square };
