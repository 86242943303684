import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Portal as ReactPortal } from 'react-portal';
import Modal from './Modal';

const Portal = ({
  title,
  children,
  body,
  closeable,
}) => {
  const [isOpen, setOpen] = useState(false);

  const closePortal = () => setOpen(false);
  const openPortal = () => setOpen(true);

  const handleEscKey = useCallback(({ keyCode }) => {
    if (keyCode === 27 && closeable) closePortal();
  }, [closeable]);

  useEffect(() => {
    if (closeable) window.addEventListener('keydown', handleEscKey);

    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, closeable, handleEscKey]);

  return (
    <>
      {isOpen && (
        <ReactPortal>
          <Modal
            title={title}
            closePortal={closePortal}
            closeable={closeable}
          >
            {body({ closePortal, openPortal, isOpen })}
          </Modal>
        </ReactPortal>
      )}
      {children({ openPortal, closePortal, isOpen })}
    </>
  );
};

Portal.defaultProps = {
  closeable: true,
};

Portal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.func.isRequired,
  body: PropTypes.func,
  closeable: PropTypes.bool,
};

export default Portal;
