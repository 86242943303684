import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Title,
  Box,
  Markdown,
  Container,
  NewsletterForm,
  theme,
} from '@components';
import newsletterBackground from './assets/Newsletter.svg';

const StyledRelative = styled.div`
  background: url(${newsletterBackground});
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
`;

const HomePageNewsletter = ({
  visible, title, markdown, button,
}) => {
  if (!visible) return null;

  return (
    <StyledRelative>
      <Container
        maxWidth={(theme.widths.small / 3) * 2}
        fontSize={[0, 1]}
      >
        <Box
          textAlign="center"
          pt={[1, 2, 3, 4, 5]}
        >
          <Title color="yellow">{title}</Title>
        </Box>
        <Box
          textAlign="center"
          pt={[5, 5, 6]}
        >
          <Markdown>{markdown}</Markdown>
          <NewsletterForm button={button} />
        </Box>
      </Container>
    </StyledRelative>
  );
};

HomePageNewsletter.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  markdown: PropTypes.string,
  button: PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.string,
  }),
};

export default HomePageNewsletter;
