import React from 'react';
import { emailValidator } from '@loform/react';
import { TextInput } from '@components';
import { auth } from '@helpers';
import { NEW_API_URL } from '@constants';
import MESSAGES from '@messages';
import { passwordLengthValidator } from '@validators';
import Form from './Form';

const errorsMap = {
  email_taken: MESSAGES.ACCOUNT_EMAIL_TAKEN,
  account_blocked: MESSAGES.ACCOUNT_EMAIL_TAKEN_BLOCKED,
  'wrong.email_format': MESSAGES.WRONG_EMAIL_FORMAT,
  'password.required': MESSAGES.PASSWORD_REQUIRED,
  'password.too_short': MESSAGES.PASSWORD_TOO_SHORT_ERROR,
};

const RegisterForm = () => (
  <Form
    url={`${NEW_API_URL}/register`}
    buttonText={MESSAGES.REGISTER}
    title={MESSAGES.REGISTRATION}
    replaceContent={auth.getToken()
      ? () => <p>{MESSAGES.YOU_ARE_ALREADY_LOGGED_IN}</p>
      : null}
    onSuccess={({ data, setMessage }) => {
      if (data.ok) {
        setMessage({
          success: (
            <>
              <p>{MESSAGES.SUCCESSFULLY_REGISTERED}</p>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: MESSAGES.REGISTER_CONFIRMATION_INFO }}
              />
            </>
          ),
        });
      }
    }}
    errorsMap={errorsMap}
  >
    {({ errors }) => (
      <>
        <TextInput
          errors={errors}
          name="email"
          placeholder={MESSAGES.EMAIL_ADDRESS}
          validators={[emailValidator(MESSAGES.WRONG_EMAIL)]}
          required
        />
        <TextInput
          errors={errors}
          name="password"
          placeholder={MESSAGES.PASSWORD}
          required
          type="password"
          validators={[passwordLengthValidator()]}
        />
        <TextInput
          errors={errors}
          name="passwordRepeat"
          placeholder={MESSAGES.REPEAT_PASSWORD}
          required
          type="password"
          validators={[
            {
              errorMessage: MESSAGES.PASSWORDS_NOT_MATCH,
              validate: (value, formValues) => value === formValues.password,
            },
          ]}
        />
      </>
    )}
  </Form>
);

export default RegisterForm;
