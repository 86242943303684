import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MESSAGES from '@messages';
import Button from './Button';

const StyledDiv = styled.div`
  text-align: center;

  span {
    font-size: 1.25em;
  }
`;

const pad = (num) => `0${num}`.substr(-2);
const isValidDate = (date) => date instanceof Date && !isNaN(date);

const getTimeLeft = (date) => {
  const now = new Date();
  const diff = date - now;
  if (diff < 0) return MESSAGES.PAYMENT_LINK_EXPIRED;

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((diff / 1000 / 60) % 60);
  const seconds = Math.floor((diff / 1000) % 60);

  return (
    <>
      {MESSAGES.PAYMENT_LINK_TIME_LEFT}
      {hours > 0 && (
        <span>
          {hours}
          {' '}
          godzin
          {' '}
        </span>
      )}
      <span>
        {pad(minutes)}
        {' '}
        min.
        {' '}
      </span>
      <span>
        {pad(seconds)}
        {' '}
        sek.
        {' '}
      </span>
    </>
  );
};

const PaymentButton = ({ date, href }) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    if (!date) return setTimeLeft(null);
    const timeLeftDate = new Date(date);
    if (!isValidDate(timeLeftDate)) return setTimeLeft(null);

    setTimeLeft(getTimeLeft(timeLeftDate));
    const interval = setInterval(() => {
      setTimeLeft(getTimeLeft(timeLeftDate));
    }, 1000);
    return () => clearInterval(interval);
  }, [date]);

  const expired = timeLeft === MESSAGES.PAYMENT_LINK_EXPIRED;

  return (
    <>
      <Button
        Tag={expired ? 'button' : 'a'}
        href={href}
        target="_blank"
        disabled={expired}
        mt={[3, 4]}
      >
        {MESSAGES.GO_TO_PRZLEWY24_PAYMENT}
      </Button>
      <StyledDiv>{timeLeft}</StyledDiv>
    </>
  );
};

PaymentButton.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  href: PropTypes.string.isRequired,
};

export default PaymentButton;
