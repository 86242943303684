import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { CalendarContext, mapEvent } from '@helpers';

const INITIAL_STATE = { events: [], flattenEvents: [] };

const CalendarProvider = ({ children, pageconfig }) => {
  const { show_events_from } = pageconfig;
  const [{ events, flattenEvents }, setState] = useState(INITIAL_STATE);

  const fromDate = useMemo(
    () => show_events_from
      ? new Date(show_events_from)
      : dayjs().startOf('month').add(3, 'hours').toDate(),
    [show_events_from]
  );

  const setEvents = useCallback(
    (eventsValue = []) => {
      const nextEvents = eventsValue.map((days) => days.map((e) => e.map(mapEvent)));
      setState({
        events: nextEvents,
        flattenEvents: nextEvents.flat(Infinity),
      });
    },
    [setState]
  );

  return (
    <CalendarContext.Provider
      value={{
        events,
        flattenEvents,
        pageconfig,
        setEvents,
        fromDate: fromDate.toISOString(),
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

CalendarProvider.defaultProps = {
  pageconfig: {},
};

CalendarProvider.propTypes = {
  pageconfig: PropTypes.shape({
    show_events_from: PropTypes.instanceOf(Date),
  }),
  children: PropTypes.elementType,
};

export default CalendarProvider;
