import styled from 'styled-components';
import {
  textAlign, display, position, maxWidth, maxHeight, fontSize, alignItems, justifyContent, flexDirection,
} from 'styled-system';
import { Box } from '@rebass/grid';
import {
  top, left, bottom, right, opacity,
} from '../styled/styled-system';

export default styled(Box)`
  ${display};
  ${position};
  ${maxWidth};
  ${maxHeight};
  ${textAlign};
  ${fontSize};
  ${top};
  ${left};
  ${bottom};
  ${right};
  ${opacity};
  ${alignItems};
  ${justifyContent};
  ${flexDirection};
`;
