import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextInput, PasswordInput, Input as LoformInput } from '@loform/react';
import { Box } from '@components';
import MESSAGES from '@messages';
import theme from './styled/theme';

const StyledErrors = styled.div`
  position: absolute;
  top: auto;
  bottom: 10px;
  left: 0;
  right: 0;
  /* conside border on both sides */
  width: calc(100% - 2px);
  transform: translateY(100%);
  font-size: 0.8em;
  color: #fff;
  background: ${theme.colors.redLight};
  color: ${theme.colors.red};
  border-bottom-left-radius: ${theme.radius.normal};
  border-bottom-right-radius: ${theme.radius.normal};
  border: 1px solid ${theme.colors.red};
  border-top: none;

  > div {
    padding: 0 1.25em;
  }
`;

const hasErrors = (errors, inputName) => !!(errors[inputName] && errors[inputName].length);
const getErrorClass = (errors, inputName) => hasErrors(errors, inputName) ? 'error' : '';
export const renderErrors = (errors, inputName) => hasErrors(errors, inputName) && (
  <StyledErrors>
    {errors[inputName].map((error) => <div key={error}>{error}</div>)}
  </StyledErrors>
);

const Input = ({
  errors = {},
  required,
  requiredMessage,
  name,
  type,
  mb,
  mr,
  ml,
  flex,
  ...props
}) => {
  let InputComponent = type === 'password' ? PasswordInput : TextInput;
  if (type === 'date') {
    InputComponent = LoformInput;
  }

  return (
    <Box
      position="relative"
      mb={mb !== undefined ? mb : [2, 3]}
      ml={ml}
      mr={mr}
      flex={flex}
    >
      {renderErrors(errors, name)}
      <InputComponent
        {...props}
        name={name}
        type={type}
        className={getErrorClass(errors, name)}
        required={required}
        requiredMessage={requiredMessage || (required ? MESSAGES.DEFAULT_REQUIRED_MESSAGE : '')}
      />
    </Box>
  );
};

Input.propTypes = {
  errors: PropTypes.shape({}),
  required: PropTypes.bool,
  requiredMessage: PropTypes.string,
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  mr: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  ml: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  flex: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  value: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  validators: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Input;
