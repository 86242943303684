import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../styled/theme';
import CalendarCell from './CalendarCell';

const Wrapper = styled(CalendarCell)`
    text-align: center;
    height: ${theme.calendar.headerHeight};
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const HeaderCell = (props) => {
  const { day } = props;

  return (
    <Wrapper>
      {day && (
        <div>{day.label}</div>
      )}
    </Wrapper>
  );
};

HeaderCell.propTypes = {
  day: PropTypes.instanceOf(Date),
};

export default HeaderCell;
