import styled from 'styled-components';
import { transparentize, lighten } from 'polished';
import {
  theme,
  Flex,
} from '@components';

export const NavbarFlexWrapper = styled(Flex)`
  .item {
    .link {
      transition: ${theme.transition.set('background')};
      text-transform: uppercase;
      text-align: center;
      font-weight: 600;

      display: block;
      padding: 1em;
      border-bottom: 3px solid transparent;

      &:hover {
        background: ${transparentize(0.7, theme.colors.yellowLight)};
      }
    }
  }

  .backdrop {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: ${theme.transition.set('visibility')}, ${theme.transition.set('opacity')};
    background: ${transparentize(0.3, 'black')};
  }


  .list {
    position: fixed;
    transform: translateX(100%);
    transition: ${theme.transition.set('transform')};
    z-index: 2;
    color: ${theme.colors.text};
    background-color: ${lighten(0.1, theme.colors.yellowLight)};
    background-image:
    linear-gradient(
      to right,
      #FFF5F5, #FFFDF8
    );
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: auto;
    min-width: 16em;
    padding-top: 4em;
  }

  .item {
    border-color: ${theme.colors.blueLight};

    &.search {
      margin-bottom: 1em;
    }

    .link {
      padding-left: 2em;
      padding-right: 1em;

      &,
      &:visited,
      &:hover,
      &:focus {
        color: ${theme.colors.text};
      }

      &.active, &.active:hover {
        color: ${theme.colors.yellow};
      }
    }
  }

  &.active {
    color: ${theme.colors.text};

    .list {
      transform: translate(0);
    }

    .backdrop {
      pointer-events: initial;
      visibility: visible;
      opacity: 1;
    }
  }
`;
