import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  GridFlex,
  Title,
  Button,
  Container,
  Markdown,
} from '@components';
import Box from '../../Grid/Box';

import {
  HomePageImage,
} from './Components';

const ImageLargeWrapper = styled(Box)`
    padding: 0;
    background-repeat: no-repeat;
    height: 700px;
    order: 1;
`;

const HomePageHero = ({
  title, markdown, buttons, image,
}) => {
  const { src: { childImageSharp: { fluid: { src } } } } = image;
  return (
    <Container
      m={0}
      p={0}
      px={0}
      py={0}
      alignItems="center"
      justifyContent="flex-end"
      position="relative"
    >
      <HomePageImage
        image={image}
        order={[0, 0, 1]}
        display={['block', 'block', 'none']}
      />
      <ImageLargeWrapper
        p={0}
        m={0}
        mr={0}
        ml="auto"
        width={[0, 0, '60%']}
        display={['none', 'none', 'block']}
      >
        <svg
          height="100%"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <clipPath id="hero-mask" clipPathUnits="objectBoundingBox"><polygon points="0 0, 1 0, 1 1, 0 1, 0 1, 0.6 0" /></clipPath>
          </defs>
          <image
            clipPath="url(#hero-mask)"
            height="90%"
            width="100%"
            xlinkHref={src}
            preserveAspectRatio="xMaxYMid slice"
          />
        </svg>
      </ImageLargeWrapper>
      <Box
        width={[1, 1, '52%']}
        p={[3, 4, 0]}
        position={['static', 'static', 'absolute']}
        top={0}
        left={0}
        ml={[0, 0, 4]}
        justifySelf="flex-start"
        order={[1, 1, 0]}
        textAlign={['center', 'center', 'initial']}
      >
        <GridFlex
          pt={[0, 2, 6, 6]}
        >
          <Title>{title}</Title>
          <Box mb={[2, 2, 3, 4]}>
            <Markdown>{markdown}</Markdown>
          </Box>
          <GridFlex
            width="100%"
          >
            <Box mx={['auto', 'auto', 'initial']}>
              {buttons.map((button) => (
                <Button
                  key={`${button.path}`}
                  {...button}
                />
              ))}
            </Box>
          </GridFlex>
        </GridFlex>
      </Box>
    </Container>
  );
}
;

HomePageHero.propTypes = {
  title: PropTypes.string,
  markdown: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.oneOf(['red', 'blue', 'yellow']),
    center: PropTypes.bool,
    path: PropTypes.string,
  })),
  image: PropTypes.shape({
    id: PropTypes.string,
    alt: PropTypes.string,
    src: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
  }),
};

export default HomePageHero;
