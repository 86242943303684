import styled from 'styled-components';
import { responsiveVariant } from '@helpers';

const listVariants = responsiveVariant({
  key: 'listVariants',
});

export default styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  ${listVariants}
`;
