import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  GridFlex,
  Box,
  Title,
  SEO,
  Markdown,
  Shape,
} from '@components';

const Shapes = () => (
  <>
    <Shape
      type="square"
      color="blue"
      size={45}
      strokeWidth={12}
      opacity={0.15}
      rotate={70}
      style={{
        bottom: '5%',
        left: '20%',
      }}
    />
    <Shape
      type="triangle"
      color="red"
      size={40}
      rotate={20}
      strokeWidth={10}
      style={{
        right: '25%',
        bottom: '20%',
      }}
    />
    <Shape
      type="circle"
      color="yellow"
      size={30}
      strokeWidth={15}
      style={{
        top: '10%',
        right: '15%',
      }}
    />
  </>
);


const Hero = ({
  title, markdown, render, seo, space, center,
}) => (
  <>
    {seo && <SEO {...seo} />}
    <Container
      py={null}
      pt={[2, 2, 3, 4]}
      pb={[4, 4, 4, 5]}
      {...space}
    >
      <GridFlex
        alignItems="stretch"
        justifyContent={['center', 'center', 'space-between']}
      >
        <Box
          width={[1, 1, render || center ? 1 : 1 / 3]}
          pr={[0, 0, 3]}
          textAlign="center"
          position="relative"
          display="flex"
          alignItems="center"
        >
          <Shapes />
          <Title
            weight="800"
            tag="h1"
            fontSize={[4, 4, 5]}
            mb={[2, 3, 0]}
          >
            {title}
          </Title>
        </Box>
        {render ? render() : (
          <Box
            width={[1, 1, 2 / 3]}
            fontSize={[1, 2, 2, 3]}
            textAlign={['center', 'center', 'initial']}
          >
            <Markdown>{markdown}</Markdown>
          </Box>
        )}
      </GridFlex>
    </Container>

  </>
);

Hero.propTypes = {
  title: PropTypes.string,
  markdown: PropTypes.string,
  render: PropTypes.func,
  seo: PropTypes.object,
  space: PropTypes.shape({}),
  center: PropTypes.bool,
};

export default Hero;
