const phoneValidator = (errorMessage) => ({
  errorMessage,
  validate: (value) => {
    if (!value) {
      return true;
    }

    return value.length >= 9;
    // TODO: not works on FF
    // const regex = /(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/im;
    // return regex.test(value);
  },
});

export default phoneValidator;
