import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Relative } from '@components';
import MESSAGES from '@messages';
import Select from 'react-select';
import { toTimeString } from './helpers';
import CalendarCell from './CalendarCell';
import CalendarRow from './CalendarRow';
import TimeCell from './TimeCell';
import HeaderCell from './HeaderCell';
import EventCell from './EventCell';
import theme from '../styled/theme';
import { DAY_NAMES_SHORT_VALUES } from '../../constants';

const Wrapper = styled.div`
  min-width: ${theme.calendar.minWidth};
`;

const Inner = styled.div`
  /* overflow: auto; */
`;

const getTime = (timeStr) => {
  const [hours, minutes] = timeStr.split(':');
  return [+hours, +minutes];
};

const pad = (num) => `0${num}`.substr(-2);

const BLOCK_SIZE = 10;
const roundBlockSize = (num) => pad(+num - (+num % BLOCK_SIZE));

const roundTime = (time) => {
  const [h, m] = getTime(time);
  return `${pad(h)}:${roundBlockSize(m)}`;
};

const isCollide = (aStart, aEnd, bStart, bEnd) => {
  const aDiff = aEnd - aStart - 100;
  const bDiff = bEnd - bStart - 100;

  return !(aStart + aDiff <= bStart || aStart > bStart + bDiff);
};

const timeToNum = (str) => (str ? +str.replace(':', '') : '');

const getOverlaps = (current, arr) => arr
  .filter(({ start_time, end_time }) => {
    const currentStart = timeToNum(current.start_time);
    const currentEnd = timeToNum(current.end_time);

    const nextStart = timeToNum(start_time);
    const nextEnd = timeToNum(end_time);

    const collide = isCollide(currentStart, currentEnd, nextStart, nextEnd);

    return (
      collide
      || (currentStart <= nextStart && currentEnd >= nextEnd + nextStart)
      || (currentStart >= nextStart && currentEnd <= nextEnd)
    );
  })
  // proper order
  .reverse();

const SELECT_STYLES = {
  menu: (baseStyle) => ({
    ...baseStyle,
    zIndex: 99999,
  }),
};

const getFirstEvent = (dayEvent) => dayEvent.find((event) => !!event);

const getDayIndex = (dayEvent) => {
  const firstEvent = getFirstEvent(dayEvent);
  if (!firstEvent) return null;
  return firstEvent.day_index;
};

const getAvailableDays = (events) => events.map((dayEvents) => {
  if (!dayEvents || !dayEvents.length) return null;
  const dayIndex = getDayIndex(dayEvents[0]);
  return DAY_NAMES_SHORT_VALUES[dayIndex];
}).filter(Boolean);

class Calendar extends Component {
  constructor(props) {
    super(props);

    const allInstructors = props.events.flatMap((dayEvents) => dayEvents.flatMap((timeEvents) => timeEvents.flatMap((event) => event.instructors)));
    const uniqueInstructors = [...new Set(allInstructors)];

    this.allDays = DAY_NAMES_SHORT_VALUES;

    this.allInstructors = uniqueInstructors.map((name) => ({
      label: name,
      value: name,
    }));

    this.state = {
      instructors: [],
      days: [],
    };
  }

  normalizeTimeBlocks = (events, days) => {
    if (!Array.isArray(events) || events.length < 0) return {};
    const timeBlocks = {};

    const { firstHour, lastHour } = events.reduce(
      (acc, cur) => {
        cur.forEach((dayEvents) => {
          // because dayEvents contains array of all upcomming events
          // we need to pull out only first one
          const firstItem = dayEvents[0];
          const [currentStart] = getTime(firstItem.start_time);
          const [currentEnd] = getTime(firstItem.end_time);

          // eslint-disable-next-line no-param-reassign
          acc = {
            firstHour:
              currentStart < acc.firstHour ? currentStart : acc.firstHour,
            lastHour: currentEnd > acc.lastHour ? currentEnd : acc.lastHour,
          };
        });
        return acc;
      },
      { firstHour: 23, lastHour: 0 }
    );

    // generate time blocks from firstHour to lastHour
    for (let hour = firstHour; hour < lastHour + 1; hour += 1) {
      for (let minutes = 0; minutes < 60; minutes += BLOCK_SIZE) {
        // TODO: round time to timeBlock
        const timeString = toTimeString(hour, minutes);
        timeBlocks[timeString] = Array.from({ length: 7 }, () => []);
      }
    }

    if (events && Array.isArray(events)) {
      events.forEach((dayEvents) => {
        if (Array.isArray(dayEvents)) {
          const currentMonthDayEvents = dayEvents.map((items) => {
            const [currentMonth] = items || [];
            return currentMonth;
          });

          dayEvents.forEach((currentTimeEvents) => {
            const event = getFirstEvent(currentTimeEvents);
            const dayIndex = event.day_index;

            if (!event) return;

            const { start_time, end_time } = event;

            // TODO: Round minutes
            const start = timeToNum(start_time);
            const end = timeToNum(end_time);

            const overlaps = getOverlaps(event, currentMonthDayEvents);

            const time = roundTime(start_time);

            const blockSpan = ((end - start + 5) / 100) * (60 / BLOCK_SIZE);
            const timeBlock = timeBlocks[time];
            const dayBlock = timeBlock && timeBlock[dayIndex];
            if (!dayBlock) return;

            timeBlocks[time][dayIndex].push({
              event: { ...event, blockSpan, overlaps },
              events: currentTimeEvents,
            });
          });
        }
      });

      const FILTER_HOURS = [];

      // Object.keys(timeBlocks).forEach((key) => {
      //   if (FILTER_HOURS.some((hour) => key.startsWith(hour))) { delete timeBlocks[key]; }
      // });

      return timeBlocks;
    }
  };

  render() {
    const { events: eventsProp = [] } = this.props;
    const { instructors } = this.state;

    let currentEvents = eventsProp;

    if (eventsProp.length) {
      // const [sunday, ...restDays] = eventsProp;
      // currentEvents = [...restDays, sunday];
    }

    const availableDays = getAvailableDays(currentEvents);

    const days = (this.state.days.length ? this.state.days : availableDays).sort((a, b) => a.value - b.value);

    const daysIndexes = days.map(({ value }) => value);

    const filteredDays = !daysIndexes.length ? currentEvents : currentEvents;
    // .filter((dayEvents, index) => daysIndexes.includes(index));

    let events = filteredDays;

    if (instructors?.length) {
      const nextEvents = availableDays.map(() => []);

      filteredDays.forEach((dayEvents) => {
        dayEvents.forEach((timeEvents) => {
          timeEvents.forEach((event) => {
            const { instructors: eventInstructors } = event;
            const hasInstructor = instructors.some((instructor) => eventInstructors.includes(instructor.value));
            if (!hasInstructor) return;

            const dayIndex = getDayIndex(timeEvents);

            const arr = nextEvents[dayIndex];
            if (!Array.isArray(arr)) return;
            arr.push([event]);
          });
        });
      });

      events = nextEvents;
    }

    const timeBlocks = this.normalizeTimeBlocks(events, days);
    const allTimeBlocks = this.normalizeTimeBlocks(eventsProp, this.allDays);

    const timeBlockEntries = Object.entries(timeBlocks);
    const allTimeBlockEntries = Object.entries(allTimeBlocks);

    if (!allTimeBlockEntries.length) return MESSAGES.NO_RESULTS;

    return (
      <Wrapper className="calendar">
        <div style={{
          textAlign: 'left', fontSize: '0.8em', display: 'flex', alignItems: 'center', gap: '2em',
        }}
        >
          <h4 style={{
            textAlign: 'left', textTransform: 'none', color: 'unset', marginTop: '1em',
          }}
          >
            Filtry
          </h4>
          <label htmlFor="instrutors" style={{ width: '100%' }}>
            Instruktorzy
            <Select
              id="instructors"
              isMulti
              onChange={(nextSelected) => this.setState({ instructors: nextSelected })}
              value={this.state.instructors}
              options={this.allInstructors}
              placeholder="Wybierz instruktorów"
              styles={SELECT_STYLES}
            />
          </label>

          <label htmlFor="days" style={{ width: '100%' }}>
            Dni
            <Select
              id="days"
              isMulti
              onChange={(nextSelected) => this.setState({ days: nextSelected })}
              value={this.state.days}
              options={availableDays}
              placeholder="Wybierz dni"
              styles={SELECT_STYLES}
            />
          </label>
        </div>
        <Inner>
          <CalendarRow>
            <TimeCell />
            <CalendarCell timeSpacing />
            {days.map((day) => <HeaderCell key={day.value} day={day} />)}

          </CalendarRow>

          <Relative>
            {timeBlockEntries.map(([time, days]) => (
              <CalendarRow key={time}>
                <TimeCell>{time}</TimeCell>
                <EventCell timeSpacing />
                {days.map((items, index) => !daysIndexes.includes(index) ? null : (
                  <EventCell
                    isNearRight={index > 2}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${time}-${index}`}
                    items={items}
                    weekend={index >= 5}
                  />
                ))}
              </CalendarRow>
            ))}
          </Relative>
        </Inner>
      </Wrapper>
    );
  }
}

Calendar.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({})))
  ),
};

export default Calendar;
