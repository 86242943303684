import React, { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Box,
  Flex,
  SmallButton,
  theme,
  Portal,
  LoginForm,
  RegisterForm,
  UserDataForm,
  CartForm,
  Cart,
  UpcommingForm,
  CashClientsSelect,
  PurchasesForm,
} from '@components';
import MESSAGES from '@messages';
import { URLS_WITH_USER_ACTIONS, TECHNICAL_BREAK } from '@constants';
import {
  UserContext, CalendarContext, setCookie, getCookie,
} from '@helpers';
import UserIcon from '../assets/icons/user-check.inline.svg';

const UPCOMMING_MODAL_SHOWED_DATE = 'upcomming-modal-showed-date';

const StyledBox = styled(Box)`
  ${(props) => props.isLogged && `
    svg {
      display: flex;
      flex-shrink: 0;
      color: ${theme.colors.green};
    }
  `};
`;

const StyledUserIcon = styled(UserIcon)`
    margin-right: 0.5rem;
`;

const Text = styled.div`
  margin: 0 0.5rem;
  line-height: 1.2;
  font-size: 0.75em;
  text-align: right;
  flex-shrink: 0;
`;

const LightText = styled.div`
  color: ${theme.colors.textLight};
`;

const portalsMap = {
  register: {
    body: (props) => <RegisterForm {...props} />,
    title: MESSAGES.REGISTRATION,
  },
  login: {
    body: (props) => <LoginForm {...props} />,
    title: MESSAGES.LOGGING_IN,
  },
  edit: {
    body: (props) => <UserDataForm {...props} />,
    title: MESSAGES.FILL_USER_DATA,
    closeable: false,
  },
  cart: {
    body: (props) => <CartForm {...props} />,
    title: MESSAGES.FINALIZING_ORDER,
  },
  upcomming: {
    body: (props) => <UpcommingForm {...props} />,
    title: MESSAGES.UPCOMMING_MONTHGROUPS,
  },
  myPurchases: {
    body: (props) => <PurchasesForm {...props} />,
    title: MESSAGES.MY_PURCHASES,
  },
};

const UserActions = ({ pathname }) => {
  const [modal, setModal] = useState({});
  const [upcommingModalShownRecently, setUpcommingModalShownRecently] = useState(
    () => getCookie(UPCOMMING_MODAL_SHOWED_DATE)
  );
  const {
    logout,
    isLogged,
    client,
    isLoadingUser,
    networkError,
    pageconfig,
    cashClients,
    isSuperUser,
    fetchClient,
    upcommingMonthgroups,
    purchases,
  } = useContext(UserContext);
  const { flattenEvents } = useContext(CalendarContext);

  const hasUpcomming = useMemo(
    () => !pageconfig.payments_disabled && !!(flattenEvents.length && upcommingMonthgroups.length),
    [flattenEvents.length, upcommingMonthgroups.length, pageconfig]
  );

  if (!URLS_WITH_USER_ACTIONS.includes(pathname.replace(/\//gi, ''))) return null;

  const { login_disabled, login_disabled_info } = pageconfig || {};
  const loginDisabled = login_disabled || TECHNICAL_BREAK;
  if (networkError) return null;

  const buttonsDisabled = isLogged || isLoadingUser || loginDisabled;
  const hasPurchases = purchases?.length > 0;

  return (
    <Portal
      body={modal.body}
      title={modal.title}
      closeable={modal.closeable}
    >
      {({ openPortal, isOpen }) => {
        if (isLogged && !client.fields.completed) {
          setModal(portalsMap.edit);
          if (!isOpen && modal === portalsMap.edit) {
            openPortal();
          }
        } else if (isLogged && hasUpcomming && !upcommingModalShownRecently) {
          setModal(portalsMap.upcomming);
          if (!isOpen && modal === portalsMap.upcomming) {
            openPortal();
            setCookie(UPCOMMING_MODAL_SHOWED_DATE, true, 2);
            setUpcommingModalShownRecently(true);
          }
        }

        return (
          <>
            <Flex justifyContent="flex-end">
              {/* {!isSuperUser && (
                <Box
                  width={[1, 1, 2 / 3]}
                  textAlign="center"
                  fontSize={[0, 0, 1]}
                  color="red"
                  mr={[1, 2]}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  lineHeight={1}
                >
                  {MESSAGES.CASH_PRICE_INFO}
                </Box>
              )} */}
              <StyledBox
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                flex={1}
                pb={[1, 2]}
                isLogged={isLogged}
              >
                {!isLogged && (
                  <SmallButton
                    disabled={buttonsDisabled}
                    color="blue"
                    onClick={(e) => {
                      setModal(portalsMap.register);
                      e.preventDefault();
                      openPortal();
                    }}
                  >
                    {MESSAGES.REGISTER}
                  </SmallButton>
                )}
                {isLogged && client ? (
                  <>
                    {/* <CashClientsSelect
                      cashClients={cashClients}
                      isSuperUser={isSuperUser}
                      fetchClient={fetchClient}
                    /> */}
                    <Text>
                      {hasPurchases && (
                        <span
                          className="link red"
                          role="button"
                          onClick={(e) => {
                            setModal(portalsMap.myPurchases);
                            e.preventDefault();
                            openPortal();
                          }}
                        >
                          {MESSAGES.MY_PURCHASES}
                        </span>
                      )}
                      {isSuperUser && `${MESSAGES.LOGGED_IN} ${MESSAGES.AS}: `}
                      {isSuperUser || hasPurchases ? ' | ' : null}
                      <span
                        className="link"
                        role="button"
                        onClick={logout}
                      >
                        {MESSAGES.LOG_OUT}
                      </span>
                      <LightText>{client.email}</LightText>
                    </Text>
                    <StyledUserIcon />
                  </>
                ) : (
                  <SmallButton
                    disabled={buttonsDisabled}
                    onClick={(e) => {
                      e.preventDefault();
                      setModal(portalsMap.login);
                      openPortal(e);
                    }}
                  >
                    {MESSAGES.LOG_IN}
                  </SmallButton>
                )}
                {isLogged && client && (!client.is_cash_only || isSuperUser) && (
                  <Cart
                    onClick={(e) => {
                      setModal(portalsMap.cart);
                      openPortal(e);
                    }}
                  />
                )}
              </StyledBox>
            </Flex>
            <Flex justifyContent="flex-end">
              {hasUpcomming && (
                <SmallButton
                  color="yellow"
                  onClick={(e) => {
                    e.preventDefault();
                    setModal(portalsMap.upcomming);
                    openPortal();
                  }}
                >
                  {MESSAGES.SHOW_UPCOMMING}
                </SmallButton>
              )}
            </Flex>
            {loginDisabled && login_disabled_info && (
              <Flex justifyContent="flex-end">
                <Text>
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: login_disabled_info }}
                  />
                </Text>
              </Flex>
            )}
          </>
        );
      }}
    </Portal>
  );
};

UserActions.propTypes = {
  pathname: PropTypes.string,
};

export default UserActions;
