import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form } from '@loform/react';
import {
  Button,
  Box,
  GridFlex,
  Loader,
  StaticError,
  StaticSuccess,
} from '@components';
import MESSAGES from '@messages';

const onlyOnSubmit = {
  getErrorsOnInputUpdate: (input, errors, prevErrors) => {
    const newErrors = new Map();

    // eslint-disable-next-line no-restricted-syntax
    for (const [inputId, inputErrors] of Array.from(errors.entries())) {
      newErrors.set(
        inputId,
        inputErrors.filter((error) => (prevErrors.get(inputId) || []).includes(error))
      );
    }

    return newErrors;
  },
};

const StyledForm = styled(Form)`
  p {
    margin: 0.5em 0;
  }
`;

class FormComponent extends Component {
  state = {
    isLoading: this.props.getInitialData,
    success: '',
    error: '',
    initialData: {},
    buttonText: this.props.buttonText || MESSAGES.DEFAULT_FORM_BUTTON_TEXT,
  }

  componentDidMount() {
    const { getInitialData } = this.props;
    if (!getInitialData || typeof getInitialData !== 'function') return;

    getInitialData()
      .then(({ data }) => {
        this.setState({ isLoading: false, initialData: data });
      })
      .catch(this.setDefaultError);
  }

  componentWillUnmount() {
    clearTimeout(this.loaderDebounce);
    delete this.loaderDebounce;

    clearTimeout(this.successTimer);
    delete this.successTimer;
  }

  clearSuccessTimer = () => clearTimeout(this.successTimer);

  clearDebounce = () => clearTimeout(this.loaderDebounce);

  setDefaultError = () => this.setMessage({ error: MESSAGES.DEFAULT_ERROR });

  clearMessages = () => this.setState(
    (state) => state.error || state.success
      ? { error: '', success: '', isLoading: false }
      : null
  )

  setMessage = (messages, cb) => {
    this.setState({
      isLoading: false,
      error: '',
      success: '',
      ...messages,
    }, () => {
      if (cb) cb();
      this.clearDebounce();
      this.clearSuccessTimer();
    });
  }

  handleSubmit = (formValues) => {
    const {
      hasCustomErrors,
      url,
      errorsMap,
      onSuccess,
      onError,
      values,
      method,
      getValues = (v) => v,
    } = this.props;

    if (hasCustomErrors) return;

    const usedValues = getValues(values || formValues);

    this.loaderDebounce = setTimeout(() => {
      this.setState({ isLoading: true });
    }, 100);

    axios({
      method: method || 'post',
      url,
      data: usedValues,
      withCredentials: true,
      credentials: 'include',
    })
      .then(({ data }) => {
        onSuccess({ data, setMessage: this.setMessage, values: usedValues });

        // set error if onSucces done nothing
        this.successTimer = setTimeout(() => {
          if (this.state.isLoading) this.setDefaultError();
        }, 3000);
      }).catch((err) => {
        const { response: { data = {} } = {} } = err || {};
        if (typeof onError === 'function') onError(data, err);
        const foundErrorMessage = errorsMap[data.message];
        this.setMessage({ error: foundErrorMessage || MESSAGES.DEFAULT_ERROR });
      });
  }

  setButtonText = (value = '') => {
    const { buttonText } = this.props;
    if (buttonText) return;

    this.setState((state) => {
      const nextButtonText = value || buttonText || MESSAGES.DEFAULT_FORM_BUTTON_TEXT;
      return state.buttonText === nextButtonText
        ? null
        : { buttonText: nextButtonText };
    });
  }

  render() {
    const {
      children,
      replaceContent,
      formService,
      hasCustomErrors,
      inline,
      buttonProps,
    } = this.props;
    const {
      buttonText,
      isLoading,
      error,
      success,
      initialData,
    } = this.state;

    if (typeof replaceContent === 'function') return replaceContent();
    return (
      <StyledForm
        onSubmit={this.handleSubmit}
        validationStrategy={onlyOnSubmit}
        formService={formService}
      >
        {({ submit, errors, isValidating }) => (
          <GridFlex
            p={0}
            position="relative"
          >
            {(isLoading || isValidating) && <Loader />}
            <Box
              width={1}
              mx="auto"
              display={inline ? 'flex' : 'block'}
            >
              {children({
                errors,
                initialData,
                setButtonText: this.setButtonText,
                clearMessages: this.clearMessages,
              })}

              <Button
                path={null}
                onClick={(e) => {
                  e.preventDefault();
                  submit(e);
                }}
                type="submit"
                Tag="button"
                color="red"
                disabled={!!success || hasCustomErrors}
                fullWidth={!inline}
                mb={0}
                {...buttonProps}
              >
                {buttonText}
              </Button>
            </Box>
            <Box width={1} margin="auto">
              {error && <StaticError mb={0}>{error}</StaticError>}
              {success && <StaticSuccess mb={0}>{success}</StaticSuccess>}
            </Box>
          </GridFlex>
        )}
      </StyledForm>
    );
  }
}

FormComponent.defaultProps = {
  errorsMap: {},
  buttonText: '',
  buttonProps: {},
};

FormComponent.propTypes = {
  buttonText: PropTypes.string,
  url: PropTypes.string,
  replaceContent: PropTypes.func,
  children: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  inline: PropTypes.bool,
  buttonProps: PropTypes.shape({}),
  onError: PropTypes.func,
  errorsMap: PropTypes.shape({}),
  getInitialData: PropTypes.func,
  formService: PropTypes.shape({}),
  values: PropTypes.shape({}),
  hasCustomErrors: PropTypes.bool,
  method: PropTypes.string,
  getValues: PropTypes.func,
};

export default FormComponent;
