import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '@components';
import { CartContext } from '@helpers';
import MESSAGES from '@messages';

import CartIcon from '../assets/icons/shopping-cart.inline.svg';

const CartIconWrapper = styled.div`
  display: flex;
  padding-left: 0.5em;
  opacity: 0.7;
  justify-content: center;
  align-items: center;
  position: relative;
  border-left: 1px solid ${theme.colors.border};
  flex-shrink: 0;
  cursor: pointer;
  
  svg {
    color: ${theme.colors.border};
  }
  
  &.hasItems {
    svg {
      color: ${theme.colors.red};
    }
  
    &:hover {
      opacity: 1;
    }
  }
`;

const Text = styled.span`
  display: block;
  font-size: 0.65em;
  line-height: 1.5;
`;

const Cart = ({ onClick }) => {
  const { items, amount } = useContext(CartContext);
  const hasItems = !!(items.length);
  return (
    <CartIconWrapper
      className={hasItems ? 'hasItems' : ''}
      onClick={onClick}
    >
      {hasItems && (
        <Text>
          {items.length}
          {' '}
          (
          {amount}
          {' '}
          {MESSAGES.PLN}
          )
        </Text>
      )}
      <CartIcon />
    </CartIconWrapper>
  );
};

Cart.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Cart;
