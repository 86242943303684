import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled, { ThemeProvider } from 'styled-components';
import config from '@data/config.json';
import { COOKIES_ACCEPTED_KEY, NEW_API_URL } from '@constants';
import { getCookie, setCookie } from '@helpers';
import {
  GlobalStyles,
  Navbar,
  Footer,
  theme,
  CookiesBar,
} from '@components';

import CalendarProvider from './CalendarProvider';
import UserProvider from './UserProvider';
import CartProvider from './CartProvider';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const BodyWrapper = styled.div`
  flex: 1;
  z-index: 0;
  position: relative;
`;

const TemplateWrapper = ({
  children,
  location,
}) => {
  const [pageconfig, setPageconfig] = useState({});
  const [cookiesAccepted, setCookiesAccepted] = useState(() => getCookie(COOKIES_ACCEPTED_KEY));
  const [isNavbarActive, setNavbarActive] = useState(false);

  const handleCookiesAgree = (event) => {
    event.preventDefault();
    setCookie(COOKIES_ACCEPTED_KEY, true);
    setCookiesAccepted(true);
  };

  useEffect(() => {
    axios.get(`${NEW_API_URL}/pageconfig`)
      .then(({ data }) => setPageconfig(data));
  }, []);

  const toggleNavbar = (value) => {
    setNavbarActive((isActive) => value === undefined ? !isActive : value);
  };

  return (
    <ThemeProvider theme={theme}>
      <UserProvider pageconfig={pageconfig}>
        <CalendarProvider pageconfig={pageconfig}>
          <CartProvider>
            <Wrapper>
              <GlobalStyles />
              <Helmet>
                <title>{config.siteTitle}</title>
                <meta
                  name="description"
                  content={config.siteDescription}
                />
                <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap&subset=latin-ext" rel="stylesheet" />
              </Helmet>
              <Navbar
                isActive={isNavbarActive}
                toggleNavbar={toggleNavbar}
                location={location}
              />
              <BodyWrapper>{children}</BodyWrapper>
              <Footer />
              {!cookiesAccepted && <CookiesBar onClick={handleCookiesAgree} />}
            </Wrapper>
          </CartProvider>
        </CalendarProvider>
      </UserProvider>
    </ThemeProvider>
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
};

export default TemplateWrapper;
