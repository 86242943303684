import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextInput } from '@components';
import MESSAGES from '@messages';
import { UserContext } from '@helpers';
import { NEW_API_URL } from '@constants';
import Form from './Form';
import FakeDatePicker from '../FakeDatePicker';

const StyledTable = styled.table`
  margin: 0 0 1em 0;
  border-collapse: collapse;
  width: 100%;
  border: none;

  & td div {
    margin-bottom: 0 !important;
  }

  & tr {
    background: transparent !important;
  }

  & td {
    border: none !important;
    text-align: left;
  }

  & td:first-child {
    width: 120px;
  }
`;

const errorsMap = {
  limit_reached: MESSAGES.PARTICIPANTS_LIMIT_REACHED,
  'wrong.birthdate': MESSAGES.WRONG_BIRTHDATE,
  'birthdate.required': MESSAGES.WRONG_BIRTHDATE,
  duplicated: MESSAGES.PARTICIPANT_DUPLICATED,
};

const ParticipantForm = ({ closePortal, setValue }) => {
  const { client, addParticipant } = useContext(UserContext);
  const delayedClose = () => setTimeout(closePortal, 1000);

  if (!client) return null;

  return (
    <Form
      url={`${NEW_API_URL}/participant`}
      buttonText={MESSAGES.ADD}
      title={MESSAGES.ADDING_PARTICIPANT_TITLE}
      onSuccess={({ data, setMessage }) => {
        if (data) {
          setMessage(
            { success: MESSAGES.SUCCESSFULLY_ADDED },
            () => {
              addParticipant(data);
              setValue(data.id);
              delayedClose();
            }
          );
        }
      }}
      errorsMap={errorsMap}
    >
      {({ errors }) => (
        <StyledTable>
          <tbody>
            <tr>
              <td>
                {MESSAGES.FIRST_NAME}
              </td>
              <td>
                <TextInput
                  errors={errors}
                  name="first_name"
                  placeholder={MESSAGES.FIRST_NAME}
                  required
                />
              </td>
            </tr>
            <tr>
              <td>
                {MESSAGES.LAST_NAME}
              </td>
              <td>
                <TextInput
                  errors={errors}
                  name="last_name"
                  placeholder={MESSAGES.LAST_NAME}
                  required
                />
              </td>
            </tr>
            <tr>
              <td>
                {MESSAGES.BIRTHDATE}
              </td>
              <td>
                <FakeDatePicker
                  errors={errors}
                  name="birthdate"
                  placeholder={MESSAGES.BIRTHDATE}
                  required
                />
              </td>
            </tr>
          </tbody>
        </StyledTable>
      )}
    </Form>
  );
};

ParticipantForm.propTypes = {
  closePortal: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default ParticipantForm;
