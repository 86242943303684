import React, { useState } from 'react';
import { Input } from '@loform/react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import MESSAGES from '@messages';
import 'react-datepicker/dist/react-datepicker.css';
import pl from 'date-fns/locale/pl';

const Wrapper = styled.div`
    input {
        width: 100% !important;
    }
`;

const Label = styled.span`
    padding-right: 0.5em;
`;

const OurDatePicker = ({ name, label, ...rest }) => {
  const [date, setDate] = useState(() => new Date());

  return (
    <Wrapper>
      <label htmlFor={name}>
        <Label>
          {label}
        </Label>
        <DatePicker
          {...rest}
          selected={date}
          onChange={setDate}
          dateFormat="dd.MM.yyyy"
          locale={pl}
          popperPlacement="top-end"
        />

        <Input
          type="hidden"
          name={label}
          value={date ? date.toDateString() : undefined}
        />
      </label>
    </Wrapper>
  );
};

export default OurDatePicker;
