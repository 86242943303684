import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  GridFlex,
  Container,
  Title,
  Img,
  Button,
  Box,
  GridBox,
  Markdown,
} from '@components';
import MESSAGES from '@messages';

import {
  HomePageContainer,
} from './Components';

import theme from '../../styled/theme';

const ItemBox = styled((props) => <Box {...props} />)`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .content,
  .gatsby-image-wrapper {
    transition: ${theme.transition.set('opacity')};
  }

  .content {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    .gatsby-image-wrapper {
      opacity: 0.3;
    }

    .content {
      opacity: 1;
    }
  }

`;

const Hr = styled.hr`
  border-color: ${theme.colors.border};
`;

const HomePageOffer = ({
  visible, title, markdown, items, button,
}) => {
  if (!visible) return null;

  return (
    <HomePageContainer>
      <GridFlex>
        <GridBox
          width={1}
          textAlign="center"
          mb={[1, 2]}
          fontSize={0}
        >
          <Title color="yellow">{title}</Title>
          <Container maxWidth="960px" py={0}>
            <Markdown>{markdown}</Markdown>
            <Box
              mt={[2, 2, 3, 4]}
              mx={['auto', 'auto', 'initial']}
            >
              <Button {...button} />
            </Box>
          </Container>
        </GridBox>
        {items.map(({
          title: itemTitle, text, image, path,
        }) => (
          <ItemBox
            key={itemTitle}
            width={[1, 1, 1 / 3]}
            textAlign="center"
            my={[2, 3, 4]}
            px={[3, 3, 4]}
          >
            <Title
              className="title"
              color="blue"
              fontSize={[0, 1, 2]}
              tag="h5"
            >
              {itemTitle}
            </Title>
            <Box className="inner">
              <Img {...image} />
              <Box
                fontSize={[0, 0, 0, 1]}
                className="content"
                position={['initial', 'initial', 'initial', 'absolute']}
                opacity={[1, 1, 1, 0]}
              >
                <div>{text}</div>
                <Button
                  path={path}
                  color="yellow"
                  size="small"
                  text={MESSAGES.MORE}
                />
              </Box>
            </Box>
            <Box
              width={1 / 2}
              my={3}
              mx="auto"
              display={['block', 'block', 'none']}
            >
              <Hr />
            </Box>
          </ItemBox>
        ))}
      </GridFlex>
    </HomePageContainer>
  );
};

HomePageOffer.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      path: PropTypes.string,
      image: PropTypes.shape({
        id: PropTypes.string,
        alt: PropTypes.string,
        src: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.object,
          }),
        }),
      }),
    })
  ),
  button: PropTypes.shape({}),
  markdown: PropTypes.string,
};

export default HomePageOffer;
