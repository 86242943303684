import React from 'react';
import PropTypes from 'prop-types';
import { Container, GridFlex } from '@components';
import theme from '../../../styled/theme';

const HomePageContainer = ({ children, ...props }) => (
  <Container
    // px={[0, 0, 3]}
    // py={[0, 0, 4]}
    px={0}
    py={0}
    maxWidth={[theme.widths.full, theme.widths.full, theme.widths.normal]}
    textAlign={['center', 'center', 'initial']}
    {...props}
  >
    <GridFlex
      alignItems="center"
      mx={0}
      p={0}
      // pt={[0, 0, 4, 5]}
    >
      {children}
    </GridFlex>
  </Container>
);

HomePageContainer.propTypes = {
  children: PropTypes.node,
};

export default HomePageContainer;
