import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  space,
  textAlign, display, position, maxWidth, maxHeight, fontSize, alignItems, justifyContent, flexDirection,
} from 'styled-system';
import { transparentize } from 'polished';
import { Link } from 'gatsby';

import {
  theme,
} from '@components';


const StyledButton = styled(({ Tag, fullWidth, ...props }) => <Tag {...props} />)`
  display: inline-block;
  cursor: pointer;
  text-align: center;
  border: none;
  background: transparent;
  border-radius: 999px;
  margin: 0.5em;
  border: none;
  transform: translateY(0);
  border: 1px solid transparent;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:disabled {
    opacity: 0.6;
    background: ${theme.colors.lightBackground};
    border-color: ${theme.colors.border};
    color: ${theme.colors.text};
    cursor: not-allowed;
  }

  ${(props) => props.fullWidth && `
    width: 100%;
  `}

  ${(props) => props.color && `
    background-color: ${theme.colors.button[props.color].background};
    transition: ${theme.transition.set('box-shadow', '0.3s', 'ease')}, ${theme.transition.set('transform', '0.1s', 'ease')};

    &, &:visited, &:active {
      color: ${theme.colors.button[props.color].color};
      border-color: ${theme.colors.button[props.color].borderColor};
    }

    &:not(:disabled) {
    &:hover, &:focus {
        transform: translateY(-2px);
        box-shadow: 4px 6px 24px ${theme.colors.button[props.color].shadow};
        background-color: ${theme.colors.button[props.color].background};
        color: ${transparentize(0.1, theme.colors.button[props.color].color)};
      }
      &:focus {
        outline: 1px solid ${theme.colors.button[props.color].shadow};
      }
      &:active {
        transform: translateY(2px);
      }
    }

  `}
  ${space};
  ${display};
  ${position};
  ${maxWidth};
  ${maxHeight};
  ${textAlign};
  ${fontSize};
  ${alignItems};
  ${justifyContent};
  ${flexDirection};
`;

const Button = ({
  text,
  visible,
  color,
  path,
  children,
  ...rest
}) => {
  if (!visible || (!children && !text)) return null;
  return (
    <StyledButton
      color={color}
      to={path}
      {...rest}
    >
      {text || children}
    </StyledButton>
  );
};

Button.defaultProps = {
  path: '#',
  color: 'red',
  visible: true,
  disabled: false,
  Tag: Link,
  px: [theme.button.small.px, theme.button.small.px, theme.button.big.px],
  py: [theme.button.small.py, theme.button.small.py, theme.button.big.py],
};

Button.propTypes = {
  text: PropTypes.string,
  children: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['red', 'blue', 'yellow']),
  visible: PropTypes.bool,
  path: PropTypes.string,
  px: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  py: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  Tag: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};


export default Button;
