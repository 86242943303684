import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { theme } from '@components';
import {
  space, fontSize, color,
} from 'styled-system';

const Title = styled(({ tag: Tag, ...props }) => <Tag {...props} />)`
  color: ${(props) => theme.colors[props.color]};
  font-weight: ${(props) => props.weight};
  width: 100%;
  ${space}
  ${fontSize}
  ${color}
`;

Title.defaultProps = {
  color: 'red',
  tag: 'h3',
  weight: '700',
  mb: [3, 3, 3, 4],
  fontSize: [1, 2, 3, 4],
};

Title.propTypes = {
  children: PropTypes.string,
  color: PropTypes.oneOf(['red', 'blue', 'yellow']),
  center: PropTypes.bool,
};

export default Title;
