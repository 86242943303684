import React from 'react';
import Button from './Button';
import theme from './styled/theme';

const SmallButton = (props) => (
  <Button
    px={theme.button.small.px}
    py={theme.button.small.py}
    fontSize={theme.button.small.fontSize}
    Tag="button"
    {...props}
  />
);

export default SmallButton;
