import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@components';
import MESSAGES from '@messages';
import rodoPdf from '../assets/docs/30.08.2019/rodo.pdf';

const RodoCheckbox = ({ errors }) => (
  <Checkbox
    errors={errors}
    name="rodo"
    required
    small
    render={() => (
      <>
        {MESSAGES.RODO_AGREEMENT}
        {' '}
        (
        <a
          href={rodoPdf} rel="noopener noreferrer"
          target="_blank"
        >
          {MESSAGES.RODO_CONDITIONS}
        </a>
        )
      </>
    )}
  />

);

RodoCheckbox.propTypes = {
  errors: PropTypes.shape({}),
};

export default RodoCheckbox;
