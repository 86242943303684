import React from 'react';
import Box from './Box';

const GridBox = (props) => (
  <Box
    fontSize={[0, 1, 1, 2]}
    {...props}
  />
);

GridBox.defaultProps = {
  fontSize: [0, 1, 1, 2],
  py: [2, 3, 4],
  px: [3, 3, 4],
};

export default GridBox;
