import React, { useState } from 'react';
import { Input } from '@loform/react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import MESSAGES from '@messages';
import 'react-datepicker/dist/react-datepicker.css';
import pl from 'date-fns/locale/pl';

const Wrapper = styled.div`
    input {
        width: 100% !important;
    }
`;

const Label = styled.span`
    padding-right: 0.5em;
`;

const DateTimePicker = () => {
  const [date, setDate] = useState(() => new Date());

  return (
    <Wrapper>
      <label htmlFor="operation_date">
        <Label>
          {MESSAGES.OPERATION_DATE}
        </Label>
        <DatePicker
          selected={date}
          onChange={setDate}
          timeInputLabel={`${MESSAGES.TIME}:`}
          dateFormat="dd.MM.yyyy HH:mm"
          showTimeInput
          locale={pl}
        />

        <Input
          type="hidden"
          name="operation_date"
          value={date ? date.toISOString() : undefined}
        />
      </label>
    </Wrapper>
  );
};

export default DateTimePicker;
