import { variant } from 'styled-system';

const responsiveVariant = (variantProps) => {
  const { prop = 'variant' } = variantProps;
  const defaultVariant = variant(variantProps);

  const fn = (props) => {
    const { [prop]: variantProp } = props;

    if (!Array.isArray(variantProp)) {
      return defaultVariant;
    }

    return {};
  };

  return fn;
};

export default responsiveVariant;
