import styled from 'styled-components';
import theme from '../styled/theme';

const CalendarCell = styled.div`
    flex: 1;
    height: ${theme.calendar.height};

    ${(props) => props.timeSpacing && `
        flex: none;
        max-width: 5em;
    `};
`;

export default CalendarCell;
