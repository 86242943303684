import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { Box } from '@components';
import theme from './styled/theme';

const MessageBox = (props) => (
  <Box
    mt={[1, 2, 3]}
    mb={[1, 2, 3]}
    py={[1, 2, 3]}
    px={[2, 3]}
    fontSize={0}
    mx="auto"
    textAlign="center"
    {...props}
  />
);

export const StaticError = styled(MessageBox)`
    background: ${theme.colors.redLight};
    color: ${theme.colors.red};
    border-radius: ${theme.radius.normal};
    border: 2px solid ${transparentize(0.8, theme.colors.red)};
  `;

export const StaticSuccess = styled(MessageBox)`
    background: ${theme.colors.greenLight};
    color: ${theme.colors.green};
    border-radius: ${theme.radius.normal};
    border: 2px solid ${transparentize(0.8, theme.colors.green)};
  `;

export const StaticWarning = styled(MessageBox)`
    background: ${theme.colors.yellowLight};
    color: ${theme.colors.text};
    border-radius: ${theme.radius.normal};
    border: 2px solid ${transparentize(0.8, theme.colors.yellow)};
  `;
