import React, { useRef, useState } from 'react';
import { Input } from '@loform/react';
import { Box } from '@components';
import MESSAGES from '@messages';
import styled from 'styled-components';
import { MONTH_NAMES } from '../constants';
import { isValidDate } from '../helpers';
import { renderErrors } from './TextInput';

const Label = styled.span`
    padding-right: 0.5em;
`;

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 1em;

  input, select {
    height: 3em;
  }
`;

const INITIALS = {
  day: undefined,
  month: 0,
  year: 2000,
};

function getDaysInMonth(values) {
  const month = values.month ?? 0;
  const year = values.year ?? 2000;

  return new Date(
    year,
    month + 1,
    0
  ).getDate();
}

const FakeDatePicker = ({
  name, label, errors, required, requiredMessage
}) => {
  const now = useRef(new Date());
  const [values, setValues] = useState(() => INITIALS);

  const date = new Date(values.year, values.month, values.day);
  const isValid = isValidDate(date);

  const updateValues = (newValues) => {
    setValues((prevValues) => {
      const nextValues = { ...prevValues, ...newValues };
      const daysInMonth = getDaysInMonth(nextValues);
      const maxDay = Math.min(daysInMonth, nextValues.day);
      return { ...nextValues, day: maxDay };
    });
  };

  return (
    <Box position="relative">
      <label htmlFor={name}>
        {label && <Label>{label}</Label>}

        <InputsWrapper>
          <input
            name={`${name}-day`}
            key={`${name}-day`}
            type="number"
            min={1}
            max={31}
            placeholder="Dzień"
            value={values.day}
            onChange={(e) => {
              updateValues({ day: e?.target?.valueAsNumber });
            }}
          />

          <select
            value={values.month}
            placeholder="Miesiąc"
            onChange={(e) => {
              const month = +e?.target?.value ?? 0;
              updateValues({ month });
            }}
          >
            {MONTH_NAMES.map((month, index) => (
              <option key={month} value={index}>{month}</option>
            ))}
          </select>

          <input
            name={`${name}-year`}
            type="number"
            min={1900}
            max={now.current.getFullYear()}
            value={values.year}
            placeholder="Rok"
            onChange={(e) => {
              const year = e?.target?.valueAsNumber;
              updateValues({ year });
            }}
          />
        </InputsWrapper>
        <Input
          type="hidden"
          name={name}
          value={isValid ? date.toISOString() : undefined}
          required={required}
          requiredMessage={requiredMessage || (required ? MESSAGES.DEFAULT_REQUIRED_MESSAGE : '')}
        />
      </label>
      {renderErrors(errors, name)}
    </Box>
  );
};

export default FakeDatePicker;
