import React from 'react';
import config from '@data/config.json';
import styled from 'styled-components';
import {
  theme,
  Container,
  Box,
} from '@components';

const Wrapper = styled.footer`
  background-color: ${theme.colors.lightBackground};
  text-align: center;
`;

const { number = {} } = config;

const Footer = () => (
  <Wrapper>
    <Container
      py={[1, 2, 3]}
      fontSize="12px"
    >
      {config.copyright}
      <Box
        width={1}
        fontSize={[1, 2]}
      >
        <strong>{number.title}</strong>
        {': '}
        <span className="number">
          <a href={number.href}>{number.text}</a>
          <Box display="inline" fontSize="0">{` ${number.info}`}</Box>
        </span>
      </Box>
    </Container>
  </Wrapper>
);

export default Footer;
