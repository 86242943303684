import React from 'react';
import Flex from './Flex';

const GridFlex = (props) => (
  <Flex
    flexWrap="wrap"
    {...props}
  />
);

export default GridFlex;
